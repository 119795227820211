<div #companyTrigger class="company-switcher-trigger" (click)="showCompanyList()">
    <ts-avatar icon="briefcase" little="true" rounded="true"></ts-avatar>
    <span class="selected-company-item" style="font-weight: 400;">{{selectedCompany.name}}</span>
</div>

<ts-simple-popover [(visible)]="showCompanies" [target]="companyTrigger" position="bottomRight" [showArrow]="false"
                   [autoClose]="true" (visibleChange)="onClose($event)">
    <div class="company-switcher-popover-wrapper">
        <div class="selected-company-switcher">
            <ts-avatar icon="briefcase" little="false" rounded="true"></ts-avatar>
            <p class="selected-company-item">{{selectedCompany.name}}</p>
        </div>

        <ts-search-box width="width" placeholder="{{'orders-list.search' | translate}}"
                       primaryText="{{'orders-list.search' | translate}}" [(value)]="filterResult"
                       (valueChange)="handleValueChangeCompanyFilter($event)"
                       (searchButtonClick)="handleValueChangeCompanyFilter($event)"></ts-search-box>

        <span style="font-size: 14px; font-weight: 700;">{{'companies.title' | translate}}</span>

        <p *ngIf="filteredCompanies.length === 0" style="font-size: 14px; font-weight: 700;text-align: center;">No data
            found!</p>
        <vapor-angular-scollable-list [items]="filteredCompanies" [tpl]="tpl" [viewClass]="'custom-scrollable-list'"
                                      maxHeight="45vh" [autoWidthDisabled]="true">
        </vapor-angular-scollable-list>
    </div>

    <ng-template #tpl let-ctx="ctx">
        <div class="company-switcher-item" (click)="onSelectCompany(ctx)">
            <ts-avatar icon="briefcase" little="true" rounded="true"></ts-avatar>
            <span>{{ctx.name}}</span>
        </div>
    </ng-template>
</ts-simple-popover>