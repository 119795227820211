import { Injectable } from '@angular/core';
import { NotificationConfig, NotificationService, NotificationStyle } from '@vapor/angular-ui';

@Injectable()
export class UiService {
    constructor(private _notification: NotificationService) { }

    showNotification(message: string, style: NotificationStyle = 'check', title?: string): void {
        const config: NotificationConfig = {
            content: message,
            type: 'toast',
            style: style,
            timeout: 5000,
            position: 'right',
        }

        if (title) {
            config['title'] = title;
        }

        this._notification.show(config);
    }
}
