<div #orderEditorPage class="order-editor-page">
    <div class="content">
        <div class="header">
            <div class="header-raw">
                <span *ngIf="!editMode">{{'orders.createEdit.createTitle' | translate}}
                    {{formOrderData.get('code')?.value ? '"' + formOrderData.get('code')?.value + '"' : ''}}</span>
                <span *ngIf="editMode">{{'orders.createEdit.editTitle' | translate}}
                    "{{formOrderData.get('code')?.value}}"</span>
            </div>
        </div>
        <div class="wrapper">
            <div class="content-wrapper">
                <ng-container *ngIf="!editMode && !showComponentsList">
                    <ng-container>
                        <div class="ts-vapor ts-layout layout-container">
                            <order-editor-new-order #orderDataComponent [editMode]="editMode" [step]="selectedTabId"
                                                    [stepList]="stepList" [formOrderData]="formOrderData"
                                                    [formCycleData]="formCycleData"
                                                    [selectStatusOrderData]="selectStatusOrderData"
                                                    [selectResourceTypeData]="selectResourceTypeData" [f]="f"
                                                    [additionalInfoOpen]="additionalInfoOpen"
                                                    [toggleAdditionalInfo]="toggleAdditionalInfo"
                                                    [additionalInfoForm]="additionalInfoForm"
                                                    [keyValueArray]="keyValueArray" [phasesArray]="phasesArray"
                                                    [phasesList]="phasesList"
                                                    [selectedProductProductionUnit]="this.formOrderData?.get('product')?.value?.ProductionUnit"
                                                    [companyId]="selectedCompanyId"
                                                    [plantHasComponentsListModule]="plantHasComponentsListModule"
                                                    (resourceTypeSelected)="onResourceTypeSelected($event)"
                                                    (workIdChangeEvent)="onWorkIdChange($event)"
                                                    (intervallChangeEvent)="intervallTimeChanged($event)"
                                                    (openComponentsListEvent)="openComponentsList($event)"
                                                    (onAddPhaseEvent)="onAddPhase($event)"
                                                    (trashClickEvent)="trashClick($event)"
                                                    (handleNewInfoButtonClickedEvent)="handleNewInfoButtonClicked()"
                                                    (handleRemoveInfoButtonClickedEvent)="handleRemoveInfoButtonClicked($event)"></order-editor-new-order>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="editMode && !showComponentsList">
                    <ts-tab-panel [panelTabs]="[{ id: 1, text: ('orders.createEdit.order' | translate), disabled: false }, {id: 2, text: ('orders.createEdit.workcycle' | translate), disabled: (!this.formOrderData.valid || !this.additionalInfoForm.valid) }]"
                                  type="primary" barType="standard" size="small" [(selectedTab)]="selectedTabId"
                                  [selectTabOnClick]="true" (selectedTabChange)="selectedTabChange($event)">
                    </ts-tab-panel>
                    <div class="ts-vapor ts-layout layout-container">
                        <order-editor-new-order #orderDataComponent [editMode]="editMode" [step]="selectedTabId"
                                                [stepList]="stepList" [formOrderData]="formOrderData"
                                                [formCycleData]="formCycleData"
                                                [selectStatusOrderData]="selectStatusOrderData"
                                                [selectResourceTypeData]="selectResourceTypeData" [f]="f"
                                                [additionalInfoOpen]="additionalInfoOpen"
                                                [toggleAdditionalInfo]="toggleAdditionalInfo"
                                                [additionalInfoForm]="additionalInfoForm" [variation]="variation"
                                                [keyValueArray]="keyValueArray" [phasesArray]="phasesArray"
                                                [phasesList]="phasesList"
                                                [selectedProductProductionUnit]="this.formOrderData?.get('product')?.value?.ProductionUnit"
                                                [companyId]="selectedCompanyId"
                                                [plantHasComponentsListModule]="plantHasComponentsListModule"
                                                (resourceTypeSelected)="onResourceTypeSelected($event)"
                                                (workIdChangeEvent)="onWorkIdChange($event)"
                                                (intervallChangeEvent)="intervallTimeChanged($event)"
                                                (openComponentsListEvent)="openComponentsList($event)"
                                                (onAddPhaseEvent)="onAddPhase($event)"
                                                (trashClickEvent)="trashClick($event)"
                                                (handleNewInfoButtonClickedEvent)="handleNewInfoButtonClicked()"
                                                (handleRemoveInfoButtonClickedEvent)="handleRemoveInfoButtonClicked($event)"></order-editor-new-order>
                    </div>
                </ng-container>
                <ng-container *ngIf="showComponentsList && plantHasComponentsListModule">

                    <div class="components-list">
                        <div class="header">
                            <div class="title">{{editMode ? ('orders.createEdit.editComponentList' | translate) :
                                ('orders.createEdit.createComponentsList' |
                                translate)}}{{phasesList?.[selectedPhaseIndex]?.workName ? (' - ' +
                                phasesList[selectedPhaseIndex].workName) : ''}}</div>
                            <ts-button [type]="'tertiary medium blue'" icon="times" iconSize="1x"
                                       (buttonClicked)="handleCancelComponentsListClicked()">
                            </ts-button>
                        </div>
                        <div class="body">
                            <div class="left-body">
                                <div class="subtitle">{{'orders.createEdit.chooseComponents' | translate}}</div>
                                <div class="layout-container">
                                    <div class="tableWrp">
                                        <ts-tree-list #productsList [data]="products" [columns]="productsColumns"
                                                      key="id" parentKey="groupId" actionsColumnCaption=""
                                                      groupedActionsTooltipText=""
                                                      emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                                                      emptyStateSecondaryText="" emptyStateSecondaryLink=""
                                                      [enablePaging]="false" [showSearchBar]="false"
                                                      [hideActionsColumn]="true" [showRowLines]="true"
                                                      [showBorders]="false" [allowRowDeleting]="false"
                                                      [allowRowInserting]="false" [allowRowUpdating]="false"
                                                      [wordWrapEnabled]="true" [height]="productsListHeight">
                                            <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>
                                            <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data
                                                         let-level="level">
                                                <div [classList]="productAlreadyAdded(data) ? ['add-product disabled'] : ['add-product']"
                                                     (click)="addProductToComponentsList(data)">
                                                    <ts-button [type]="productAlreadyAdded(data) ? 'tertiary medium' : 'tertiary medium blue'"
                                                               icon="plus" iconSize="1x"
                                                               (buttonClicked)="addProductToComponentsList(data)">
                                                    </ts-button>
                                                    <span>{{'orders.createEdit.add' | translate}}</span>
                                                </div>
                                            </ng-template>
                                        </ts-tree-list>
                                    </div>
                                </div>
                            </div>
                            <div class="right-body">
                                <div class="subtitle">
                                    <span>
                                        {{'orders.createEdit.componentsList' | translate}}
                                    </span>
                                    <div class="counter">{{componentsListToSave.length}}</div>
                                </div>
                                <div class="searchWrapper">
                                    <ts-text-box class="search"
                                                 placeholderText="{{'orders.createEdit.search' | translate}}"
                                                 width="100%" (valueChanged)="onSearchChange($event)">
                                    </ts-text-box>
                                    <ts-button class="searchBtn" [type]="'tertiary medium white'" icon="search"
                                               iconSize="1x" [height]="'32px'" (buttonClicked)="onSearch()">
                                    </ts-button>
                                </div>
                                <form [formGroup]="quantityForm" *ngIf="quantityForm">
                                    <div class="list-to-save" formArrayName="quantityArray">
                                        <div *ngFor="let info of quantityArray.controls; let i = index "
                                             [formGroupName]="i">

                                            <div
                                                 [classList]="searchMatchesProduct(componentsListToSave[i].product) ? ['component'] : ['component hidden']">
                                                <div class="codeWrapper">
                                                    <span class="code">{{ componentsListToSave[i].product.code
                                                        }}</span>
                                                    <div class="remove"
                                                         (click)="removeProductToComponentsList(componentsListToSave[i].product)">
                                                        <ts-button [type]="'tertiary medium alert'" icon="minus"
                                                                   iconSize="1x"
                                                                   (buttonClicked)="removeProductToComponentsList(componentsListToSave[i].product)">
                                                        </ts-button>
                                                        <span>{{'orders.createEdit.remove' | translate}}</span>
                                                    </div>
                                                </div>
                                                <div class="descriptionWrapper">
                                                    <span class="descriptionLight">{{'orders.createEdit.description'
                                                        |
                                                        translate}}</span>
                                                    <span class="descriptionValue">{{
                                                        componentsListToSave[i].product.description }}</span>
                                                </div>
                                                <div *ngIf="componentsListToSave[i]?.Variation1"
                                                     class="descriptionWrapper">
                                                    <span class="descriptionLight">{{'products.newEditProduct.variation'
                                                        | translate}}</span>
                                                    <span class="descriptionValue">{{
                                                        componentsListToSave[i]?.Variation1?.code }} - {{
                                                        componentsListToSave[i]?.Variation1?.description }}</span>
                                                </div>
                                                <div class="quantityWrapper">
                                                    <div class="input-wrapper">
                                                        <ts-text-box primaryText="{{'orders.createEdit.quantity' | translate}} *"
                                                                     placeholderText="{{'orders.createEdit.quantity' | translate}}"
                                                                     formControlName="quantity"
                                                                     [validationDict]="customValidationDict | translateJSON | async"
                                                                     [showErrors]="true" width="180px"></ts-text-box>
                                                        {{ componentsListToSave[i].quantity }}
                                                        <span
                                                              class="suffix">{{componentsListToSave[i].product?.ProductionUnit?.unit
                                                            ?
                                                            componentsListToSave[i].product?.ProductionUnit?.unit.length
                                                            > 4 ?
                                                            componentsListToSave[i].product?.ProductionUnit?.unit.substr(0,
                                                            3) + "..." :
                                                            componentsListToSave[i].product?.ProductionUnit?.unit :
                                                            ""}}</span>
                                                    </div>
                                                </div>
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="footer" style="position: fixed; min-height: unset;">
                            <div>
                                <ts-button [type]="'secondary medium blue'"
                                           (buttonClicked)="handleCancelComponentsListClicked()"
                                           text="{{'orders.createEdit.cancelButton' | translate}}">
                                </ts-button>
                                <ts-button [type]="'primary medium blue'"
                                           (buttonClicked)="handleSaveComponentsListClicked()"
                                           text="{{'orders.createEdit.saveButton' | translate}}">
                                </ts-button>
                            </div>
                        </div>
                    </div>


                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="footer" *ngIf="!showComponentsList">
    <div>
        <ts-button [type]="'secondary medium blue'" (buttonClicked)="handleCancelClicked()"
                   text="{{'orders.createEdit.cancelButton' | translate}}">
        </ts-button>
        <ts-button *ngIf="selectedTabId == 1" [type]="'primary medium blue'" (buttonClicked)="handleNextClicked()"
                   [disabled]="(!formOrderData.valid || checkTargetValue()) || !additionalInfoForm.valid"
                   text="{{'orders.createEdit.nextButton' | translate}}">
        </ts-button>
        <ts-button *ngIf="selectedTabId == 2 && editMode" [type]="'primary medium blue'"
                   (buttonClicked)="handleSaveClicked()" [disabled]="!formCycleData.valid"
                   text="{{'orders.createEdit.saveEditButton' | translate}}">
        </ts-button>
        <ts-button *ngIf="!editMode && selectedTabId == 2" [type]="'primary medium blue'"
                   (buttonClicked)="handleSaveClicked()" [disabled]="false"
                   text="{{'orders.createEdit.saveButton' | translate}}">
        </ts-button>
    </div>
</div>