import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { DocIstance, TaskIstance, UnitInstance } from '../../../models/task.model';
import { UnitService } from '../../../services/unit.service';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { TaskService } from '../../../services/task.service';
import { FileSizePipe } from '../../../pipes/file-size.pipe';

@Component({
  selector: 'app-control-task-drawer',
  templateUrl: './control-task-drawer.component.html',
  styleUrls: ['./control-task-drawer.component.scss'],
  providers: [FileSizePipe]
})
export class ControlTaskDrawerComponent implements OnInit {

  tabs = [];
  selectedTabId: number;
  units: UnitInstance[] = [];
  documentColumns: TsTreeListColumn[] = [];
  taskColumns: TsTreeListColumn[] = [];
  docs: DocIstance[];
  selectedDocument: DocIstance;
  srcResult: File = null;

  tasks: TaskIstance[] = [];
  filteredTasks: TaskIstance[] = [];

  @Input() controlID: number;
  @Input() controlTask?: TaskIstance;
  @Input() companyID: number;
  @Input() plantID: number;
  @Input() currentTasks?: TaskIstance[];
  @Input() isCloning: boolean = false;

  @Output() selectedTaskEmit = new EventEmitter<any>();

  form: FormGroup;
  showForm: boolean = false;
  showActions: boolean = true;
  useExisting: boolean = false;

  selectedUnit: UnitInstance;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  constructor(
    private _fb: FormBuilder,
    private _unit: UnitService,
    private _task: TaskService,
    private _fileSize: FileSizePipe,
    private _translate: TranslateService
  ) {
    this.form = this._fb.group({
      description: [null],
      closedQuestion: [false],
      taskWithValues: [false],
      task_parameters: this._fb.array([this.createParametersGroup()]),
      instructionType: [null],
      docLink: [null],
      docText: [null]
    });
  }

  private createParametersGroup(): FormGroup {
    return this._fb.group({
      description: [''],
      unit: [null],
      tolerance_threshold: [false],
      parameters: this._fb.group({
        minThreshold: [null],
        maxThreshold: [null],
        targetValue: [null],
        outOfRangeDescription: [null]
      }),
    });
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  get _taskParams(): FormArray {
    return this.form.get('task_parameters') as FormArray;
  }

  getToleranceThresholdValue(index: number): boolean {
    const taskParam = this._taskParams.at(index) as FormGroup;
    return taskParam.get('tolerance_threshold').value;
  }

  updateToleranceThresholdValue(index: number, value: boolean): void {
    const taskParam = this._taskParams.at(index) as FormGroup;
    taskParam.get('tolerance_threshold').setValue(value);

    if (taskParam.get('tolerance_threshold').value === false) {
      taskParam.get('parameters').get('minThreshold').reset();
      taskParam.get('parameters').get('maxThreshold').reset();
      taskParam.get('parameters').get('targetValue').reset();
      taskParam.get('parameters').get('outOfRangeDescription').reset();
    }
  }

  addEmptyTaskParameter() {
    this._taskParams.push(this.createParametersGroup());
  }

  deleteTaskParameter(index: number) {
    this._taskParams.removeAt(index);
  }

  async ngOnInit() {
    this.tabs = [{
      id: 1,
      text: this._translate.instant('control.dialog.question-details'),
      disabled: false,
    }, {
      id: 2,
      text: this._translate.instant('control.dialog.documentation'),
      disabled: false,
    }];

    this.selectedTabId = 1; // Default tab

    this.units = await this._unit.get();

    // Reset path src value

    this.srcResult = null;

    if (this.controlTask) {
      this.showActions = false;
      this.showForm = true;
      this.updateForm(this.controlTask)
    } else {
      this.addNewTask();
    }

    await this.populateDocumentTable();
  }


  async populateDocumentTable() {
    this.docs = await this._task.getDocs(this.companyID, this.plantID);

    this.docs = this.docs.map((doc, i) => ({
      ...doc,
      id: i // Add ID key to make a document unique
    }))

    const defaultColumn: TsTreeListColumn = {
      dataField: '',
      headerPlaceholder: this._translate.instant('orders-list.search'),
      allowFiltering: false,
      allowEditing: false,
      allowSorting: false,
    };

    this.documentColumns = [
      {
        ...defaultColumn,
        dataField: 'originalname',
        allowFiltering: true,
        caption: this._translate.instant('control.dialog.columns.uploaded-instruction'),
      },
      {
        ...defaultColumn,
        dataField: 'size',
        caption: this._translate.instant('control.dialog.columns.size'),
        calculateDisplayValue: (row: DocIstance) => this._fileSize.transform(row.size),
      },
      {
        ...defaultColumn,
        cellTemplate: "tplActions",
      }
    ]

  }

  // fetch and filter existing task to associate
  private async _fetchTask() {
    this.tasks = await this._task.get(this.companyID, this.plantID);
    this.tasks.forEach((task) => {
      let check = false;
      task.ControlsTasks.forEach((c) => {
        if (c.controlId === this.controlID) check = true;
      });
      if (!check) {
        this.filteredTasks.push(task);
      }
    });

    this.filteredTasks = [...this.filteredTasks];
  }

  private updateTaskTable() {
    const defaultColumn: TsTreeListColumn = {
      dataField: '',
      headerPlaceholder: this._translate.instant('orders-list.search'),
      allowFiltering: false,
      allowEditing: false,
      allowSorting: false,
    };

    this.taskColumns = [
      {
        ...defaultColumn,
        dataField: 'description',
        caption: this._translate.instant('control.dialog.columns.description'),
      },
      {
        ...defaultColumn,
        dataField: 'closedQuestion',
        dataType: 'boolean',
        caption: this._translate.instant('control.dialog.columns.closedQuestion'),
      },
      {
        ...defaultColumn,
        dataField: 'openQuestion',
        dataType: 'boolean',
        caption: this._translate.instant('control.dialog.columns.openQuestion'),
        calculateCellValue: (row: TaskIstance) => row.unitId || (row.TasksParameters && row.TasksParameters.length > 0),
      },
      {
        ...defaultColumn,
        dataField: 'closedQuestion',
        dataType: 'boolean',
        caption: this._translate.instant('control.dialog.columns.docType'),
        calculateCellValue: (row: TaskIstance) => row.docLink || row.docText !== '' || row.docText,
      },
      {
        ...defaultColumn,
        cellTemplate: "tplActions",
      }
    ]
  }

  async chooseExisting() {
    this.showForm = false;
    this.showActions = false;
    this.useExisting = true;
    await this._fetchTask();
    this.updateTaskTable();
  }

  addNewTask() {
    this.showForm = true;
    this.useExisting = false;
  }

  async onSelectedTask(taskId: number) {
    const task = this.tasks.find(t => t.id === taskId);
    const res = await this._task.associate(taskId, this.controlID, true);
    if (res.status === 200) {
      this.currentTasks.push(task);
      this.selectedTaskEmit.emit(this.currentTasks);
      console.log("task associated successfully");
    } else {
      console.log("failed to associate task");
    }
  }


  selectDocument(doc: DocIstance) {
    this.selectedDocument = doc;
  }

  handleFileInput(files: FileList) {
    this.srcResult = files.item(0);
    this.selectedDocument = {
      originalname: this.srcResult.name,
      size: this.srcResult.size,
    }
  }

  async onSubmit() {

    if (!this.form.valid) return;

    try {
      const data: FormData = new FormData();

      data.append('controlId', this.controlID.toString());
      data.append('description', this.form.value.description);
      data.append('unitId', null);
      data.append('closedQuestion', this.form.value.closedQuestion ? 'true' : 'false');

      if (this.form.value.instructionType === 0) {
        data.append('docText', this.form.value.docText);
      } else if (this.form.value.instructionType === 1) {
        // File type
        if (this.srcResult) {
          data.append('pdf', this.srcResult, this.srcResult.name);
        } else if (this.form.value.docLink && this.form.value.docLink !== '') {
          data.append('docLink', this.form.value.docLink);
        }
      }

      if (this.form.value.taskWithValues) {
        const task_parameters = this.form.value.task_parameters;

        const parameters = task_parameters.map((param) => ({
          description: param.description,
          unitId: param.unit.id,
          minThreshold: param.parameters.minThreshold,
          maxThreshold: param.parameters.maxThreshold,
          targetValue: param.parameters.targetValue,
          outOfRangeDescription: param.parameters.outOfRangeDescription,
          insertThresholds: param.tolerance_threshold
        }));

        data.append('parameters', JSON.stringify(parameters));
      } else {
        data.append('parameters', null);
      }

      if (this.controlTask && !this.isCloning) {
        // Edit task
        const res = await this._task.update(data, this.controlTask.id);
        if (res) {
          this.currentTasks = this.currentTasks.map((task) => (task.id === this.controlTask.id) ? res : task);
          this.selectedTaskEmit.emit(this.currentTasks);
        }

      } else {
        // Create task
        data.append('companyId', this.companyID.toString());
        const res = await this._task.create(data);
        this.currentTasks.push(res);
        this.selectedTaskEmit.emit(this.currentTasks);
      }

      return { saved: true };
    } catch (err) {
      console.log(err)
      return { saved: false }
    }

  }

  updateForm(task: TaskIstance) {
    this.form.patchValue({
      description: task.description,
      closedQuestion: task.closedQuestion,
      taskWithValues: task.TasksParameters.length > 0,
      instructionType: task.docLink !== null ? 1 : 0,
      docLink: task.docLink,
      docText: task.docText
    });

    if (task.TasksParameters.length > 0) {
      // Clear any existing form controls in the task parameters array before patching new ones
      this._taskParams.clear();

      task.TasksParameters.forEach((param) => {
        const taskParamsGroup = this._fb.group({
          description: param.description,
          unit: this.units.find((u) => u.id === param.unitId),
          tolerance_threshold: param.maxThreshold !== null || param.minThreshold !== null || param.targetValue !== null,
          parameters: this._fb.group({
            minThreshold: param.minThreshold,
            maxThreshold: param.maxThreshold,
            targetValue: param.targetValue,
            outOfRangeDescription: param.outOfRangeDescription
          })
        });

        // Add the new group to the FormArray
        this._taskParams.push(taskParamsGroup);
      });
    }

    if (task.docLink) {
      this.selectedDocument = {
        originalname: task.docLink,
      }
    }
  }

}
