<ts-layout class="dataWrapper" mode="flex" tsFxLayout="column" tsFxLayoutAlign="start start" padding="16px"
           gap="0px 0px">
    <ts-layout-item class="centralInfo" padding="0px 0px">
        <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start">
            <ts-layout-item class="infoItem" padding="0px 0px">
                <ts-sub-rg class="title">{{'quality-controls.columns.description' | translate}}</ts-sub-rg>
                <span *ngIf="control?.description && control?.description !== ''">{{control?.description}}</span>
                <span *ngIf="!control?.description || control?.description === ''">
                    {{'quality-controls.columns.no-description' | translate}}
                </span>
            </ts-layout-item>
        </ts-layout>
        <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start">
            <ts-layout-item class="infoItem" padding="0px 0px">
                <ts-sub-rg>{{'quality-controls.columns.schedulings' | translate}}</ts-sub-rg>
                <div *ngIf="control.customSchedulingsLabels.length > 0">
                    <span *ngFor="let label of control.customSchedulingsLabels">
                        {{label}}
                    </span>
                </div>
                <span
                      *ngIf="control?.customSchedulingsLabels?.length === 0 && (control.description === '' || control.description.includes('copy'))">
                    {{'quality-controls.columns.none' | translate}}
                </span>
            </ts-layout-item>
            <ts-layout-item class="infoItem" padding="0px 0px">
                <ts-sub-rg>{{'quality-controls.columns.devices' | translate}}</ts-sub-rg>
                <div *ngIf="control.Devices.length > 0">
                    <span *ngFor="let device of control.Devices">
                        {{device.label}}
                    </span>
                </div>
                <span
                      *ngIf="control?.Devices?.length === 0 && (control.description === '' || control.description.includes('copy'))">
                    {{'quality-controls.columns.none' | translate}}
                </span>
                <span
                      *ngIf="control?.Devices?.length === 0 && control.description !== '' && !control.description.includes('copy')">
                    {{'control.all-devices' | translate}}
                </span>
            </ts-layout-item>
        </ts-layout>
        <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start">
            <ts-layout-item class="infoItem" padding="0px 0px">
                <ts-sub-rg>{{'quality-controls.columns.products' | translate}}</ts-sub-rg>
                <div *ngIf="control.Products.length > 0">
                    <span *ngFor="let product of control.Products">
                        {{product.code}} - {{product.name}}
                    </span>
                </div>
                <span
                      *ngIf="control?.Products?.length === 0 && (control.description === '' || control.description.includes('copy'))">
                    {{'quality-controls.columns.none' | translate}}
                </span>
                <span
                      *ngIf="control?.Products?.length === 0 && control.description !== '' && !control.description.includes('copy')">
                    {{'control.all-products' | translate}}
                </span>
            </ts-layout-item>
            <ts-layout-item class="infoItem" padding="0px 0px">
                <ts-sub-rg>{{'quality-controls.columns.tasks' | translate}}</ts-sub-rg>
                <div *ngIf="control.Tasks.length > 0">
                    <span *ngFor="let task of control.Tasks">
                        {{task.description}}
                    </span>
                </div>
                <span *ngIf="control?.Tasks?.length < 1">
                    {{'quality-controls.columns.none' | translate}}
                </span>
            </ts-layout-item>
        </ts-layout>
        <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start" gap="32px 0px" padding="0px 0px">
            <ts-layout-item class="infoItem">
                <ts-sub-rg class="ts-input-container__header__primary__text">{{'quality-controls.columns.enabled' |
                    translate}}</ts-sub-rg>
                <span>{{control.enabled ? "Yes" : "No"}}</span>
            </ts-layout-item>
        </ts-layout>

    </ts-layout-item>
</ts-layout>