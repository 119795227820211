import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidebarService {
    private selectedSidebarSubject = new BehaviorSubject<string | null>(null);
    selected: string;
    selectedSidebar$ = this.selectedSidebarSubject.asObservable();

    constructor(private _translate: TranslateService) { }

    setSelected(sel) {
        this.selected = sel;
        let target = '';

        switch (sel) {
            case 'categories':
            case 'stops':
            case 'tags':
                target = this._translate.instant('stopReasons.stops'); // Stops
                break;

            case 'orders-list':
            case 'works':
                target = this._translate.instant('orders-list.title'); // Orders
                break;

            case 'products':
            case 'units':
            case 'families':
                target = this._translate.instant('products.title'); // Products
                break;

            case 'devices':
            case 'resources':
            case 'new-device':
            case 'process_data':
                target = this._translate.instant('devices.title'); // Devices
                break;

            case 'operators':
            case 'users':
                target = this._translate.instant('users.title'); // Users
                break;

            case 'quality-controls':
                target = this._translate.instant('quality-controls.title'); // Quality Controls
                break;
            case 'shifts':
                target = this._translate.instant('schedules.schedules'); // Schedules
                break;

            default:
                break;
        }

        this.selectedSidebarSubject.next(target);
    }
}
