import { Component, ViewEncapsulation, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from '../../../services/navbar.service';
import { CompanyInstance } from '../../../models/company.model';
import { DeviceInstance } from '../../../models/device.model';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { CompanyService } from '../../../services/company.service';
import { DeviceService } from '../../../services/device.service';
import { SidebarService } from '../../../services/sidebar.service';
import { ErrorCategoryInstance } from '../../../models/error-category.model';
import { ErrorService } from '../../../services/error.service';
import { UiService } from '../../../services/ui.service';
import { ErrorType } from '../../../models/error.model';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { Drawer, FontService, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { CategoryDrawerComponent } from '../../ui/category-drawer/category-drawer.component';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-control',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CategoriesComponent implements OnInit {

    isAdmin: boolean;
    tabs = [];
    selectedTabId: number;

    companies: CompanyInstance[];

    devices: DeviceInstance[];

    selectedCompany: CompanyInstance;

    selectedCompanyId: number;

    plannedColumns: any[] = [];

    unPlannedColumns: any[] = [];

    tmpPlannedColumns: TsTreeListColumn[] = [];
    tmpUnPlannedColumns: TsTreeListColumn[] = [];

    errorCategoryList: ErrorCategoryInstance[];
    selectedErrorCategory: ErrorCategoryInstance;

    plannedList: ErrorCategoryInstance[];

    tmpPlannedList: ErrorCategoryInstance[];

    unPlannedList: ErrorCategoryInstance[];

    tmpUnPlannedList: ErrorCategoryInstance[];

    categoriesListHeight = '100vh';

    private _subscriptions: Subscription[] = [];

    @ViewChild('categoriesList', { static: false, read: ElementRef }) categoriesListRef!: ElementRef;
    @ViewChild('editErrorsTemplate', { static: false }) editErrorsTemplate: TemplateRef<any>;
    @ViewChild('newErrorsTemplate', { static: false }) newErrorsTemplate: TemplateRef<any>;
    @ViewChild('errorsDrawerBottomTemplate', { static: false }) errorsDrawerBottomTemplate: TemplateRef<any>;
    @ViewChild('categoryDrawer', { static: false }) categoryDrawer: CategoryDrawerComponent;

    constructor(
        private _device: DeviceService,
        private _company: CompanyService,
        private _auth: AuthService,
        private _ui: UiService,
        private _navbar: NavbarService,
        private _translate: TranslateService,
        private _sidebar: SidebarService,
        private _drawer: Drawer,
        private _error: ErrorService,
        private _popup: PopupService,
        private _font: FontService,
        private _spinner: OverlaySpinnerService,
        private _router: Router
    ) {
        this._font.addIcon(faPencil, faTrashAlt);

        // Collapse drawer when the user navigates to another page
        const routerEventSubscription =
            this._router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this._drawer.collapse();
                }
            });
        this._subscriptions.push(routerEventSubscription);
    }

    async ngOnInit() {

        this.isAdmin = this._auth.user.role === UserRole.admin;

        this._translate.stream([
            'categories.title',
            'categories.planned',
            'categories.unplanned',
        ]).subscribe(async (translations) => {
            this._navbar.setTitle(translations['categories.title']);
            this._sidebar.setSelected('categories');

            this.tabs = [{
                id: 1,
                text: this._translate.instant('stopReasons.planned'),
            }, {
                id: 0,
                text: this._translate.instant('stopReasons.unplanned'),
            }]

            this.selectedTabId = 1 // default tab: Planned


            const defaultColumn: TsTreeListColumn = {
                dataField: '',
                allowFiltering: false,
                allowEditing: false,
                allowSorting: false,
                allowResizing: false,
                width: '100%'
            }

            this.tmpPlannedColumns = [{
                ...defaultColumn,
                dataField: 'name',
                caption: translations["categories.planned"],
                dataType: 'string',
                allowFiltering: true
            }, { // actions
                ...defaultColumn,
                caption: '',
                fixedPosition: 'right',
                alignment: 'center',
                width: 135,
                allowFiltering: false,
                allowResizing: false,
                allowFixing: false,
                cellTemplate: 'tplActions',
            }];

            this.tmpUnPlannedColumns = [{
                ...defaultColumn,
                dataField: 'name',
                caption: translations["categories.unplanned"],
                dataType: 'string',
                allowFiltering: true
            }, { // actions
                ...defaultColumn,
                caption: '',
                fixedPosition: 'right',
                alignment: 'center',
                width: 135,
                allowFiltering: false,
                allowResizing: false,
                allowFixing: false,
                cellTemplate: 'tplActions',
            }];
        });

        this.selectedCompanyId = Number(localStorage.getItem('companyId'));

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                this.companyChanged(company);
            });

        this._subscriptions.push(companyChangeSubscription);

        if (this.isAdmin) {
            this.companies = await this._company.getCompanies();

            if (this.selectedCompanyId) {
                this.selectedCompany = this.companies.find((company) => company.id === this.selectedCompanyId);
                await this.companyChanged(this.selectedCompany);
            } else if (this.companies.length > 0) {
                this.selectedCompany = this.companies[0];
                await this.companyChanged(this.selectedCompany);
            }
        } else {
            this.devices = await this._device.getDevices();
            if (!this.selectedCompanyId && this.devices.length > 0) {
                this.selectedCompanyId = this.devices[0].Company.id;
            }
            await this._createErrorCategoriesColums(await this._error.getErrorCategories(this.selectedCompanyId));
        }

    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.categoriesListRef) {
            const offsetTop = this.categoriesListRef.nativeElement.offsetTop;
            this.categoriesListHeight = `calc(100vh - ${offsetTop}px)`;
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    async companyChanged(company: CompanyInstance): Promise<void> {
        this._spinner.show();
        this.selectedCompany = company;
        this.selectedCompanyId = this.selectedCompany.id;
        localStorage.setItem('companyId', this.selectedCompanyId.toString());
        await this._createErrorCategoriesColums(await this._error.getErrorCategories(this.selectedCompany.id));
        this._spinner.removeOverlay();
    }

    private async _createErrorCategoriesColums(categories: ErrorCategoryInstance[]): Promise<void> {

        this.plannedList = [];
        this.unPlannedList = [];

        for (const category of categories) {

            if (category.type === ErrorType.planned) {
                this.plannedList.push(category);
            } else {
                this.unPlannedList.push(category);
            }
        }

        this.tmpPlannedList = this._clone(this.plannedList);
        this.tmpUnPlannedList = this._clone(this.unPlannedList);
    }

    showCreateOrUpdateError(error?: ErrorCategoryInstance) {
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        if (error) {
            this.selectedErrorCategory = error;
        }

        this._drawer.open({
            title: error ? error.name : this._translate.instant('categories.title'),
            subTitle: '',
            expanded: false,
            marginTop: 0,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: error ? this.editErrorsTemplate : this.newErrorsTemplate,
            bottomBarTemplate: this.errorsDrawerBottomTemplate,
        }).subscribe(() => {
            this._drawer.expand();
        });
    }

    public async save(category: ErrorCategoryInstance): Promise<void> {
        let isEdit = false;

        try {
            if (category.id) {
                isEdit = true;
                const result = await this._error.updateErrorCategories(category.id, category.name);
                if (result === 200) {
                    this._ui.showNotification(this._translate.instant('categories.saved-successfully'), 'check');
                } else {
                    this._ui.showNotification(this._translate.instant('categories.saved-error'), 'error');
                    return;
                }
            } else {
                isEdit = false;
                category = await this._error.createErrorCategories(category);
                if (category) {
                    this._ui.showNotification(this._translate.instant('categories.saved-successfully'), 'check');
                } else {
                    this._ui.showNotification(this._translate.instant('categories.saved-error'), 'error');
                    return;
                }
            }

            if (category.type === ErrorType.planned) {
                if (category.id && isEdit) {
                    this.tmpPlannedList = this.tmpPlannedList.map(cat => cat.id === category.id ? category : cat);
                } else {
                    this.tmpPlannedList = [...this.tmpPlannedList, category];
                }
            } else if (category.type === ErrorType.unplanned) {
                if (category.id && isEdit) {
                    this.tmpUnPlannedList = this.tmpUnPlannedList.map(cat => cat.id === category.id ? category : cat);
                } else {
                    this.tmpUnPlannedList = [...this.tmpUnPlannedList, category];
                }
            }
        } catch (error) {
            this._ui.showNotification(this._translate.instant('categories.saved-error'), 'error');
            console.log(error)
        } finally {
            this._drawer.close();
        }

    }

    public delete(category: ErrorCategoryInstance) {

        try {
            const popupConfig: PopupConfig = {
                title: this._translate.instant('categories.dialog.title'),
                level: 'error',
                showCloseButton: true,
                visible: true,
                dragEnabled: false,
                bottomButtons: {
                    primary: {
                        text: this._translate.instant('dialogs.delete.confirm'),
                    },
                    tertiary: {
                        text: this._translate.instant('dialogs.delete.cancel'),
                    },
                },
            };

            const bodyConfig: PopupBodyConfig = {
                content: this._translate.instant('categories.dialog.text', { prod: category.name })
            };

            this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {

                if (result === 'primary') {
                    const res = await this._error.deleteErrorCategories(category.id);

                    if (res === 400) {
                        this._ui.showNotification(this._translate.instant('categories.category-used'), 'alert');
                    }

                    if (category.type === ErrorType.planned) {
                        this.plannedList = this.plannedList.filter((cat) => cat.id !== category.id);
                        this.tmpPlannedList = this._clone(this.plannedList);
                    } else if (category.type === ErrorType.unplanned) {
                        this.unPlannedList = this.unPlannedList.filter((cat) => cat.id !== category.id);
                        this.tmpUnPlannedList = this._clone(this.unPlannedList);
                    }

                    this._ui.showNotification(this._translate.instant('categories.deleted-successfully'), 'check');
                } else {
                    return;
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    onFormSubmit() {
        const res = this.categoryDrawer.onSubmit();

        const category_data = {
            ...res,
            type: this.selectedTabId,
            companyId: this.selectedCompanyId
        };

        this.save(category_data)
    }


    handleTabChange(event: any) {
        this.selectedTabId = event;
    }

    private _clone(array: any[]): any[] {
        return array.map(x => Object.assign({}, x));
    }

    onCancelDrawerClicked() {
        this._drawer.close();
    }

}
