<div class="new-units-page">
    <div class="content">
        <div class="header">
            <div class="top-row">
                <span>{{'productionUnits.title' | translate}}</span>
                <div class="arrow-container">
                    <div class="arrow"></div>
                </div>
                <span *ngIf="!editMode">{{'productionUnits.createEdit.createTitle' | translate}}</span>
                <span *ngIf="editMode">{{'productionUnits.createEdit.editTitle' | translate}}</span>
            </div>
            <div class="bottom-row">
                <div class="bottom-arrow-wrp">
                    <ts-button [type]="'primary small white'" icon="arrow-left"
                               (buttonClicked)="handleCancelClicked()"></ts-button>
                </div>
                <div class="submenu-label-wrp">
                    <span *ngIf="editMode">{{'productionUnits.createEdit.editTitle' | translate}}</span>
                    <span *ngIf="!editMode">{{'productionUnits.createEdit.createTitle' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="content-wrp">
            <form [formGroup]="form">
                <div class="section">
                    <span class="title">{{'productionUnits.columns.unit' | translate}}</span>


                    <div class="input-row">
                        <ts-text-box primaryText="{{'productionUnits.columns.unit' | translate}} *"
                                     placeholderText="{{'productionUnits.columns.unit' | translate}}"
                                     [validationDict]="customValidationDict | translateJSON | async"
                                     formControlName="unit" [enabled]="true" [showErrors]="true" maxLength="10"></ts-text-box>
                    </div>
                    <div class="input-row">
                        <vapor-angular-text-area class=" text-area"
                                                 primaryText="{{'productionUnits.columns.description' | translate}} *"
                                                 placeholderText="{{'productionUnits.columns.description' | translate}}"
                                                 formControlName="description" [minLength]="1" [maxLength]="100"
                                                 [validationDict]="customValidationDict | translateJSON | async"
                                                 [showErrors]="true"></vapor-angular-text-area>
                    </div>
                </div>

                <div class="section">
                    <span class="title">{{'productionUnits.columns.decimals' | translate}}</span>

                    <div class="input-row">
                        <div>
                            <ts-select class="select" [data]="decimalsData" [clearable]="true"
                                       primaryText="{{'productionUnits.columns.decimals' | translate}} *"
                                       placeholderText="{{'productionUnits.columns.decimals' | translate}}"
                                       formControlName="decimals"></ts-select>
                            <div class="info">
                                <ts-avatar [icon]="'info-square'" little="true"
                                           customStyles="background: transparent; width: 25px; height: 25px; transform: scale(0.8);"></ts-avatar>
                                <span>{{'productionUnits.createEdit.decimalsInfo' | translate}}</span>
                            </div>
                        </div>
                        <ng-container *ngIf="decimalsExample" class="input-row">
                            <ts-text-box class="select" [enabled]="false"
                                         primaryText="{{'productionUnits.columns.example' | translate}}"
                                         placeholderText="{{'productionUnits.columns.example' | translate}}"
                                         value="{{decimalsExample}}"></ts-text-box>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="footer">
    <div>
        <ts-button [type]="'secondary medium blue'" (buttonClicked)="handleCancelClicked()"
                   text="{{'productionUnits.createEdit.cancelButton' | translate}}">
        </ts-button>
        <ts-button [type]="'primary medium blue'" (buttonClicked)="handleSaveClicked()"
                   text="{{'productionUnits.createEdit.saveButton' | translate}}">
        </ts-button>
    </div>
</div>