<div class="family-page">
    <div class="content">
        <div class="header">
            <div class="top-row">
                <span>{{'productsFamily.title' | translate}}</span>
                <div class="arrow-container">
                    <div class="arrow"></div>
                </div>
                <span *ngIf="!editMode">{{'productsFamily.createEdit.createTitle' | translate}}</span>
                <span *ngIf="editMode">{{'productsFamily.createEdit.editTitle' | translate}}</span>
            </div>
            <div class="bottom-row">
                <div class="bottom-arrow-wrp">
                    <ts-button [type]="'primary small white'" icon="arrow-left"
                               (buttonClicked)="handleCancelClicked()"></ts-button>
                </div>
                <div class="submenu-label-wrp">
                    <span *ngIf="editMode">{{'productsFamily.createEdit.editTitle' | translate}}</span>
                    <span *ngIf="!editMode">{{'productsFamily.createEdit.createTitle' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="content-wrp">
            <form [formGroup]="form">
                <div class="section">
                    <span class="title">{{'productsFamily.columns.family' | translate}}</span>


                    <div class="input-row">
                        <ts-text-box primaryText="{{'productsFamily.columns.code' | translate}} *" [enabled]="!editMode"
                                     placeholderText="{{'productsFamily.columns.code' | translate}}"
                                     [validationDict]="customValidationDict | translateJSON | async"
                                     formControlName="code" [showErrors]="true"></ts-text-box>
                    </div>
                    <div class="input-row">
                        <vapor-angular-text-area class=" text-area"
                                                 primaryText="{{'productsFamily.columns.description' | translate}} *"
                                                 placeholderText="{{'productsFamily.columns.description' | translate}}"
                                                 formControlName="description" [minLength]="1" [maxLength]="100"
                                                 [validationDict]="customValidationDict | translateJSON | async"
                                                 [showErrors]="true"></vapor-angular-text-area>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="footer">
    <div>
        <ts-button [type]="'secondary medium blue'" (buttonClicked)="handleCancelClicked()"
                   text="{{'productsFamily.createEdit.cancelButton' | translate}}">
        </ts-button>
        <ts-button [type]="'primary medium blue'" (buttonClicked)="handleSaveClicked()"
                   text="{{'productsFamily.createEdit.saveButton' | translate}}">
        </ts-button>
    </div>
</div>