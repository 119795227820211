
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QRCodeModule } from 'angular2-qrcode';
import { MomentModule } from 'ngx-moment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthService } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { DeviceService } from './services/device.service';
import { ErrorService } from './services/error.service';
import { HttpService } from './services/http.service';
import { NavbarService } from './services/navbar.service';
import { ProductService } from './services/product.service';
import { SidebarService } from './services/sidebar.service';
import { UiService } from './services/ui.service';
import { ProcessDataService } from './services/processData.service';
import { ViewService } from './services/view.service';
import { ProductFamilyService } from './services/productFamily.service';

import { Utility } from './utils/utility';

import { CmsComponent } from './cms/cms.component';

import { ErrorsComponent } from './components/pages/errors/errors.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { NewProductComponent } from './components/pages/new-product/new-product.component';
import { FamiliesComponent } from './components/pages/families/families.component';
import { WorksComponent } from './components/pages/works/works.component';
import { DevicesComponent } from './components/pages/devices/devices.component';
import { LoginComponent } from './components/pages/login/login.component';
import { TagsComponent } from './components/pages/tags/tags.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { UsersComponent } from './components/pages/users/users.component';
import { NavbarComponent } from './components/ui/navbar/navbar.component';
import { NewDeviceComponent } from './components/pages/new-device/new-device.component';
import { ResourcesComponent } from './components/pages/resource/resource.component';
import { CompanyService } from './services/company.service';
import { ModulesService } from './services/modules.service';
import { TagsService } from './services/tag.service';
import { FilterCategoryPipe } from './pipes/filter-category.pipe';
import { ShiftsComponent } from './components/pages/work-shift/work-shift.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { UserService } from './services/user.service';
import { WorkShiftsService } from './services/shift.service';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeGr from '@angular/common/locales/el';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TaskService } from './services/task.service';
import { ControlSchedulingService } from './services/controlScheduling.service';
import { WINDOW_PROVIDERS } from './services/window.service';
import { FileSizePipe } from './pipes/file-size.pipe';
import { JsonTranslatorPipe } from './pipes/json-translator.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { OrdersService } from './services/orders.service';
import { OperationsService } from './services/operations.service';
import { WorksService } from './services/works.service';
import { OrdersCoreService } from './services/ordersCore.service';
import { QualityControlsComponent } from './components/pages/quality-controls/quality-controls.component';
import { ControlComponent } from './components/pages/control/control.component';
import { ControlsService } from './services/controls.service';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { ProcessDataComponent } from './components/pages/process-data/process-data.component';
import { PlantService } from './services/plant.service';
import { PluginService } from './services/plugin.service';
import { WorkProcessService } from './services/workProcess.service';
import { UnitService } from './services/unit.service';
import { ThemeService } from "./services/theme.service";
import { PageWithLoader } from "./components/pages/page-with-loader";
import { LoaderService } from './services/loader.service';
import { LoaderComponent } from './components/ui/loader/loader.component';
import { OperatorsComponent } from './components/pages/operators/operators.component';
import { OperatorService } from './services/operator.service';
import { OrdersListComponent } from './components/pages/orders-list/orders-list.component';
import { EnvConfigurationService } from './services/env-config.service';
import { VariationsService } from './services/variations.service';
import { APP_INITIALIZER } from '@angular/core';

import { Overlay } from '@angular/cdk/overlay';
import { AvatarComponent, NotificationModule, ButtonComponent, LayoutComponent, OverlaySpinnerComponent, OverlaySpinnerDirective, OverlaySpinnerServiceModule, PageTitleComponent, TabPanelComponent, TagComponent, PopupModule, BottomBarComponent, H3Component, SelectComponent, BodyLargeComponent, BodyRegularComponent, CodeComponent, CtaComponent, DescriptiveComponent, EyebrowComponent, H1Component, H1LeadComponent, H2Component, H2LeadComponent, H3LeadComponent, H4Component, H5Component, HyperlinkComponent, LargeSubtitleComponent, RegularSubtitleComponent, LayoutItemComponent, LayoutTitleComponent, DividerComponent, AccordionComponent, AccordionPanelComponent, AutocompleteComponent, DrawerModule, NumberBoxComponent, SimpleSelectComponent, TextBoxComponent, RadioComponent, SimpleCheckboxComponent, SimpleRadioComponent, ActionTooltipComponent, ActionTooltipDirective, TextAreaComponent, UiShellComponent, UiShellHeaderComponent, UiShellHeaderLeftColumnComponent, UiShellHeaderRightColumnComponent, UiShellSidebarComponent, UiShellSidebarItemComponent, UiShellSecondaryItemComponent, UiShellTertiaryItemComponent, UiShellQuaternaryItemComponent, UiShellSidebarBottomSectionComponent, UiShellToggleRightPanelComponent, UiShellMainComponent, SimplePopoverComponent, ScrollableListComponent, SearchBoxComponent, NotificationService } from '@vapor/angular-ui';
import { TableModule, TreeListModule, RangeDatepickerModule, DatepickerModule } from '@vapor/angular-ui-extra';
import { OrdersListCellComponent } from './components/ui/orders-list-cell/orders-list-cell.component';
import { ProductsListCellComponent } from './components/ui/products-list-cell/products-list-cell.component';
import { ResourceListCellComponent } from './components/ui/resource-list-cell/resource-list-cell.component';
import { OrderInfoComponent } from './components/ui/order-info/order-info.component';
import { OrderDrawerComponent } from './components/ui/order-drawer/order-drawer.component';
import { OrderGroupInfoComponent } from './components/ui/order-group-info/order-group-info.component';
import { OrdersGroupEditorComponent } from './components/ui/orders-group-editor/orders-group-editor.component';
import { AddOrdersToGroupComponent } from './components/ui/add-orders-to-group/add-orders-to-group.component';
import { ProductInfoDrawerComponent } from './components/ui/product-info-drawer/product-info-drawer.component';
import { OrderEditorNewOrderComponent } from './components/ui/order-editor-new-order/order-editor-new-order.component';
import { ResourceDrawerComponent } from './components/ui/resource-drawer/resource-drawer.component';
import { IconComponent } from '@vapor/angular-ui';
import { GenericDialogComponent } from './components/ui/generic-dialog/generic-dialog.component';
import { UnitsComponent } from './components/pages/units/units.component';
import { UnitsListCellComponent } from './components/ui/units-list-cell/units-list-cell.component';
import { NewUnitComponent } from './components/pages/new-unit/new-unit.component';
import { FamiliesListCellComponent } from './components/ui/families-list-cell/families-list-cell.component';
import { WorksListCellComponent } from './components/ui/works-list-cell/works-list-cell.component';
import { NewFamilyComponent } from './components/pages/new-family/new-family.component';
import { NewWorkComponent } from './components/pages/new-work/new-work.component';
import { OrderEditorComponent } from './components/pages/order-editor/order-editor.component';
import { OperatorsListCellComponent } from './components/ui/operators-list-cell/operators-list-cell.component';
import { OperatorInfoComponent } from './components/ui/operator-info/operator-info.component';
import { OperatorDrawerComponent } from './components/ui/operator-drawer/operator-drawer.component';
import { DevicesDrawerComponent } from './components/ui/devices-drawer/devices-drawer.component';
import { NewDeviceDrawerComponent } from './components/ui/new-device-drawer/new-device-drawer.component';
import { UsersListCellComponent } from './components/ui/users-list-cell/users-list-cell.component';
import { UserDrawerComponent } from './components/ui/user-drawer/user-drawer.component';
import { UserInfoComponent } from './components/ui/user-info/user-info.component';
import { CustomXsrfInterceptor } from './services/custom-xsrf.interceptor';
import { CompanyDrawerComponent } from './components/ui/company-drawer/company-drawer.component';
import { ErrorsListCellComponent } from './components/ui/errors-list-cell/errors-list-cell.component';
import { ErrorsDrawerComponent } from './components/ui/errors-drawer/errors-drawer.component';
import { ControlTaskDrawerComponent } from './components/ui/control-task-drawer/control-task-drawer.component';
import { ProcessDataDrawerComponent } from './components/ui/process-data-drawer/process-data-drawer.component';
import { TagsDrawerComponent } from './components/ui/tags-drawer/tags-drawer.component';
import { CategoryDrawerComponent } from './components/ui/category-drawer/category-drawer.component';
import { QualityControlsListCellComponent } from './components/ui/quality-controls-list-cell/quality-controls-list-cell.component';
import { QualityControlDrawerComponent } from './components/ui/quality-control-drawer/quality-control-drawer.component';
import { ToggleComponent } from './components/ui/toggle/toggle.component';
import { CompanySwitcherComponent } from './components/ui/company-switcher/company-switcher.component';

registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeGr);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function initConfig(configService: EnvConfigurationService) {
    // load the confiFg file in this function
    return async () => await configService.load();
}

@NgModule({
    declarations: [
        AppComponent,
        CmsComponent,
        ErrorsComponent,
        LoginComponent,
        NavbarComponent,
        ProductsComponent,
        NewProductComponent,
        FamiliesComponent,
        WorksComponent,
        ProcessDataComponent,
        DevicesComponent,
        NewDeviceComponent,
        ResourcesComponent,
        TagsComponent,
        ShiftsComponent,
        UsersComponent,
        OperatorsComponent,
        QualityControlsComponent,
        ControlComponent,
        CategoriesComponent,
        FileSizePipe,
        JsonTranslatorPipe,
        KeysPipe,
        FilterCategoryPipe,
        ClickOutsideDirective,
        PageWithLoader,
        LoaderComponent,
        PageWithLoader,
        OrdersListComponent,
        OrdersListCellComponent,
        OrderDrawerComponent,
        OrderInfoComponent,
        OrderGroupInfoComponent,
        OrdersGroupEditorComponent,
        ProductsListCellComponent,
        AddOrdersToGroupComponent,
        ProductInfoDrawerComponent,
        OrderEditorNewOrderComponent,
        ResourceListCellComponent,
        ResourceDrawerComponent,
        GenericDialogComponent,
        UnitsComponent,
        UnitsListCellComponent,
        NewUnitComponent,
        FamiliesListCellComponent,
        WorksListCellComponent,
        NewFamilyComponent,
        NewWorkComponent,
        OrderEditorComponent,
        OperatorsListCellComponent,
        OperatorInfoComponent,
        OperatorDrawerComponent,
        DevicesDrawerComponent,
        NewDeviceDrawerComponent,
        UsersListCellComponent,
        UserDrawerComponent,
        UserInfoComponent,
        CompanyDrawerComponent,
        ErrorsListCellComponent,
        ErrorsDrawerComponent,
        ControlTaskDrawerComponent,
        ProcessDataDrawerComponent,
        TagsDrawerComponent,
        CategoryDrawerComponent,
        QualityControlsListCellComponent,
        QualityControlDrawerComponent,
        ToggleComponent,
        CompanySwitcherComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AvatarComponent,
        NgxDatatableModule,
        QRCodeModule,
        MomentModule,
        DragDropModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        // Vapor:
        ButtonComponent,
        DrawerModule,
        DividerComponent,
        OverlaySpinnerComponent,
        OverlaySpinnerDirective,
        OverlaySpinnerServiceModule,
        LayoutComponent,
        LayoutItemComponent,
        LayoutTitleComponent,
        PageTitleComponent,
        TabPanelComponent,
        TagComponent,
        TableModule,
        TreeListModule,
        TextBoxComponent,
        DatepickerModule,
        SelectComponent,
        NumberBoxComponent,
        AutocompleteComponent,
        BottomBarComponent,
        H1LeadComponent,
        H2LeadComponent,
        H3LeadComponent,
        H1Component,
        H2Component,
        H3Component,
        H4Component,
        H5Component,
        RegularSubtitleComponent,
        LargeSubtitleComponent,
        SimpleSelectComponent,
        NotificationModule,
        AccordionComponent,
        AccordionPanelComponent,
        RangeDatepickerModule,
        PopupModule,
        EyebrowComponent,
        DescriptiveComponent,
        HyperlinkComponent,
        BodyRegularComponent,
        BodyLargeComponent,
        CtaComponent,
        CodeComponent,
        RadioComponent,
        SimpleCheckboxComponent,
        SimpleRadioComponent,
        ActionTooltipComponent,
        ActionTooltipDirective,
        TextAreaComponent,
        UiShellComponent,
        UiShellHeaderComponent,
        UiShellHeaderLeftColumnComponent,
        UiShellHeaderRightColumnComponent,
        UiShellSidebarComponent,
        UiShellSidebarItemComponent,
        UiShellSecondaryItemComponent,
        UiShellTertiaryItemComponent,
        UiShellQuaternaryItemComponent,
        UiShellSidebarBottomSectionComponent,
        UiShellToggleRightPanelComponent,
        UiShellMainComponent,
        SimplePopoverComponent,
        ScrollableListComponent,
        SearchBoxComponent,
        [IconComponent]
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [EnvConfigurationService],
            multi: true
        },
        {
            provide: LOCALE_ID,
            useFactory: () => localStorage.getItem('lang') || 'en',
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomXsrfInterceptor,
            multi: true
        },
        AuthService,
        ConfigService,
        CompanyService,
        ModulesService,
        DeviceService,
        ErrorService,
        HttpService,
        NavbarService,
        ProductService,
        WorkProcessService,
        SidebarService,
        UiService,
        TagsService,
        ControlSchedulingService,
        WorkShiftsService,
        ClickOutsideDirective,
        UserService,
        OrdersService,
        OperationsService,
        WorksService,
        OrdersCoreService,
        ControlsService,
        TaskService,
        UnitService,
        PlantService,
        Utility,
        ProcessDataService,
        PluginService,
        ViewService,
        ThemeService,
        LoaderService,
        OperatorService,
        ProductFamilyService,
        VariationsService,
        WINDOW_PROVIDERS,
        NotificationService,
        Overlay,
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class AppModule { }