import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FontService, NotificationStyle, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';
import { AuthService } from '../../../services/auth.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { Subscription } from 'rxjs';
import { UserRole } from '../../../models/user.model';
import { CompanyInstance } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { WorkInstance } from '../../..//models/work.model';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Router } from '@angular/router';
import { WorksService } from '../../../services/works.service';

@Component({
    selector: 'works',
    templateUrl: './works.component.html',
    styleUrls: ['./works.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class WorksComponent implements OnInit {

    isAdmin: boolean;
    isManager: boolean;
    selectedCompany: CompanyInstance;
    selectedCompanyId: number;
    _subscriptions: Subscription[] = [];
    worksColumns: TsTreeListColumn[] = [];
    worksListHeight = 'calc(100vh - 220px)';
    works: WorkInstance[];
    selectedWork: WorkInstance = null;
    managedOptions: any[];

    @ViewChild('worksList', { static: false, read: ElementRef }) worksListRef!: ElementRef;

    constructor(
        private _ui: UiService,
        private _font: FontService,
        private _auth: AuthService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _spinner: OverlaySpinnerService,
        private _router: Router,
        private _translate: TranslateService,
        private _company: CompanyService,
        private _work: WorksService,
        private _popup: PopupService
    ) {
        this._font.addIcon(faPencil, faTrashAlt);
    }

    async ngOnInit() {
        this._spinner.show();
        try {
            const translationSubscription = this._translate.stream([
                'products.works.title',
                'products.works.code',
                'products.works.description',
                'products.works.managed',
                'products.works.managedOptions.no',
                'products.works.managedOptions.yes'
            ]).subscribe((translations) => {
                this._navbar.setTitle(translations['products.works.title']);
                this._sidebar.setSelected('works');

                const defaultColumn: TsTreeListColumn = {
                    dataField: '',
                    headerPlaceholder: translations['products.search'],
                    allowFiltering: true,
                    allowEditing: false,
                    allowSorting: true
                };
                this.managedOptions = [
                    {
                        "value": false,
                        "label": translations['products.works.managedOptions.no']
                    },
                    {
                        "value": true,
                        "label": translations['products.works.managedOptions.yes']
                    }
                ];
                this.worksColumns =
                    [
                        { // id
                            caption: 'id',
                            dataField: 'id',
                            showColumn: false,
                        },
                        { // code
                            ...defaultColumn,
                            caption: translations['products.works.code'],
                            dataField: 'code',
                            dataType: 'string',
                            width: 302,
                            sortOrder: 'asc'
                        },
                        { // description
                            ...defaultColumn,
                            caption: translations['products.works.description'],
                            dataField: 'description',
                            dataType: 'string',
                        },
                        { // managed
                            ...defaultColumn,
                            caption: translations['products.works.managed'],
                            lkpDataSource: this.managedOptions,
                            lkpKeyField: 'value',
                            lkpResultField: 'label',
                            dataField: 'managed',
                            dataType: 'boolean',
                            calculateDisplayValue: this.calculateDisplayValue,
                            calculateCellValue: this.calculateCellValue,
                            width: 120,
                        },
                        { // actions
                            ...defaultColumn,
                            caption: '',
                            fixedPosition: 'right',
                            alignment: 'center',
                            width: 110,
                            allowFiltering: false,
                            allowResizing: false,
                            allowFixing: false,
                            allowSorting: false,
                            cellTemplate: 'tplActions',
                        },
                    ];
            });
            this._subscriptions.push(translationSubscription);

            this.isAdmin = this._auth.user.role === UserRole.admin;
            this.isManager = this._auth.user.role === UserRole.manager;
            this.selectedCompanyId = Number(localStorage.getItem('companyId'));
            if (this.isAdmin || this.isManager) {
                await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
            } else {
                this.selectedCompany = await this._company.getCompany(this.selectedCompanyId);
            }
            const companyChangeSubscription =
                this._company.changeCompanyEmitted$.subscribe(company => {
                    if (this.isAdmin) {
                        this.companyChanged(company, true);
                    }
                });
            this._subscriptions.push(companyChangeSubscription);
            this._spinner.removeOverlay();
        } catch (err) {
            this._ui.showNotification(err, 'alert');
        } finally {
            this._spinner.removeOverlay();
        }
    }

    calculateDisplayValue = (rowData) => {
        return rowData.managed ? this._translate.instant('products.works.managedOptions.yes') : this._translate.instant('products.works.managedOptions.no');
    }

    calculateCellValue = (rowData) => {
        return rowData.managed;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    async companyChanged(company: CompanyInstance, showSpinner: boolean = false) {
        if (!company) {
            return;
        }
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;

        if (showSpinner) this._spinner.show();
        try {
            this.works = await this._work.getWorksForCompany(this.selectedCompanyId);
        } catch (err) {
            console.warn(err);
        } finally {
            if (showSpinner) this._spinner.removeOverlay();
        }
    }

    onNewWorkClick() {
        this._router.navigate(['works/new']);
    }

    onDeleteWorkClicked(work: WorkInstance) {
        this.selectedWork = work;

        try {
            const popupConfig: PopupConfig = {
                title: this._translate.instant('products.works.modal.delete.title'),
                level: 'error',
                showCloseButton: true,
                visible: true,
                dragEnabled: false,
                bottomButtons: {
                    primary: {
                        text: this._translate.instant('dialogs.del'),
                    },
                    tertiary: {
                        text: this._translate.instant('dialogs.cancel'),
                    },
                },
            };

            const bodyConfig: PopupBodyConfig = {
                content: this._translate.instant('products.works.modal.delete.message', { name: work.code })
            };

            this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {
                if (result === 'primary') {
                    // TODO: delete single work
                    // const result = await this._work.deleteWork(this.selectedWork.id);
                    // const deleted = result === 200;
                    // const { title, message, style } = this.createToast(deleted);
                    // this._ui.showNotification(message, style, title);
                    console.log('deleted')
                } else {
                    return
                }
            })
        } catch (error) {
            const { title, message, style } = this.createToast(false)
            this._ui.showNotification(message, style, title);
        }
    }

    onEditWorkClicked(work: WorkInstance) {
        this._router.navigate([`works/edit/${work.id}`]);
    }

    private createToast(deleted: boolean): { title: string, message: string, style: NotificationStyle } {
        let title: string;
        let message: string;
        let style: NotificationStyle = deleted ? 'check' : 'error';
        if (deleted) {
            message = this._translate.instant('products.works.modal.delete.successText', { name: this.selectedWork.code });
            title = this._translate.instant('products.works.modal.delete.successTitle');
        } else if (!deleted) {
            message = this._translate.instant('products.works.modal.delete.errorText');
            title = this._translate.instant('products.works.modal.delete.errorTitle');
        }

        return { title, message, style };
    }

}
