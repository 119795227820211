<div class="shift-page" (contextmenu)="onRightClick()">
    <div class="shift-page-content">
        <div class="header">
            <div class="column">
                <h2>{{'work-shifts.title' | translate}}</h2>
            </div>
            <div class="column">
                <div class="right">
                    <ts-select [data]="devices" [value]="selectedDevice" labelKey="label"
                               (valueChange)="deviceChanged($event)" [enableSearch]="true"
                               [clearable]="false"></ts-select>
                </div>
            </div>
        </div>

        <div class="calendar-wrapper">
            <div class="calendar-left-wrapper">
                <ts-button text="{{'work-shifts.copy-week' | translate}}" type="primary medium blue"
                           (buttonClicked)="cloneWeek()"></ts-button>

                <ts-button text="{{'work-shifts.today' | translate}}" type="secondary medium blue" mwlCalendarToday
                           [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)"></ts-button>

            </div>
            <div class="calendar-center-wrapper">
                <i class="fa fa-angle-left week-selector" mwlCalendarPreviousView [view]="'week'"
                   [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)"></i>
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}</h3>
                <i class="fa fa-angle-right week-selector" mwlCalendarNextView [view]="'week'" [(viewDate)]="viewDate"
                   (viewDateChange)="viewDateChange.next(viewDate)"></i>
            </div>

            <div class="calendar-right-wrapper">
                <div class="cr-row">
                    <app-toggle [id]="'forceStop'" label="{{ 'work-shifts.force-stop' | translate }}"
                                [value]="forceStop" (onChange)="onForceStopChange($event)"></app-toggle>
                    <i class="fa fa-question-circle" [tsActionTooltip]="tooltipTemplate" [showTooltip]="showTooltip[0]"
                       (mouseover)="handleTooltip(0,'work-shifts.force-stop-tooltip')"
                       (mouseleave)="hideTooltip(0)"></i>
                </div>

                <div class="cr-row">
                    <app-toggle [id]="'changeStop'" label="{{ 'work-shifts.change-stop' | translate }}"
                                [value]="changeStop" (onChange)="onChangeStopChange($event)"></app-toggle>
                    <i class="fa fa-question-circle" [tsActionTooltip]="tooltipTemplate" [showTooltip]="showTooltip[1]"
                       (mouseover)="handleTooltip(1,'work-shifts.change-stop-tooltip')"
                       (mouseleave)="hideTooltip(1)"></i>
                </div>

                <div class="cr-row">
                    <app-toggle [id]="'startSession'" label="{{ 'work-shifts.start-session' | translate }}"
                                [value]="startSession" (onChange)="onStartSessionChange($event)"></app-toggle>
                    <i class="fa fa-question-circle" [tsActionTooltip]="tooltipTemplate" [showTooltip]="showTooltip[2]"
                       (mouseover)="handleTooltip(2,'work-shifts.start-session-tooltip')"
                       (mouseleave)="hideTooltip(2)"></i>

                </div>

                <ng-template #tooltipTemplate>
                    <span style="background-color: #152935;
                    color: white;
                    width: 100%;
                    padding: 8px;
                    white-space: pre-wrap;
                    font-size: 15px;">{{tooltipContent | translate}}</span>
                </ng-template>

            </div>
        </div>


        <ng-template #weekViewHourSegmentTemplate let-segment="segment" let-locale="locale"
                     let-segmentHeight="segmentHeight" let-isTimeLabel="isTimeLabel">

            <div #segmentElement class="cal-hour-segment" [style.height.px]="segmentHeight"
                 [class.cal-hour-start]="segment.isStart" [class.cal-after-hour-start]="!segment.isStart"
                 [ngClass]="segment.cssClass" (mousedown)="startDragToCreate(segment, $event, segmentElement)">

                <div class="cal-time" *ngIf="isTimeLabel"> {{ segment.date | calendarDate:'weekViewHour':locale }}
                </div>
            </div>
        </ng-template>

        <mwl-calendar-week-view [hourSegmentHeight]="hourSegmentHeight" [hourSegments]="hourSegments"
                                [viewDate]="viewDate" [events]="events" [locale]="locale" [weekStartsOn]="weekStartsOn"
                                [refresh]="refresh_changed" [hourSegmentTemplate]="weekViewHourSegmentTemplate"
                                (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-week-view>
    </div>
</div>