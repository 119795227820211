<div class="resources-page">
	<div class="resources-content">
		<div class="header">
            <div class="column">
                <h2>{{'devices.resources' | translate}}</h2>
            </div>
            <div class="column">
                <div class="right">
                    <ng-container *ngIf="plants?.length > 1">
                        <ts-select [data]="plants" [value]="plantValue" [clearable]="false" (valueChange)="handlePlantChange($event, true)"></ts-select>
                    </ng-container>
                </div>
            </div>
        </div>	

        <ng-container>
            <div class="ts-vapor ts-layout layout-container">     
				<div class="row">
					<div class="column">
						<div class="button-group start"></div>
					</div>
					<div class="column">
						<div class="button-group end">
							<ts-button icon="plus"
							           text="{{'resources.actions.new' | translate}}"
                                       type="primary medium blue"
									   [tsActionTooltip]="newResourceTooltip"
									   [tsTooltipPosition]="'bottomRight'"
									   [showTooltip]="showNewResourceTooltip"
									   (buttonClicked)="this.showNewResourceTooltip = !this.showNewResourceTooltip">
							</ts-button>
							<ng-template #newResourceTooltip class="new-resource">
								<div class="text-container">
									<ts-h5 class="text-container__margin" (mwlClick)="createDepartment()">
										{{'resources.actions.createDepartment' | translate}}</ts-h5>
									<ts-h5 class="text-container__margin" (mwlClick)="createWorkCenter()">
										{{'resources.actions.createWorkCenter' | translate}}</ts-h5>
								</div>
							</ng-template>
						</div>
					</div>
				</div>

				<ts-tree-list #resourcesList [ngClass]="'restree'" [data]="resources" [columns]="columns" key="keyId" parentKey="parentKeyId" 
				actionsColumnCaption="" groupedActionsTooltipText=""
				emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
				emptyStateSecondaryText="" emptyStateSecondaryLink="" [enablePaging]="false"
				[showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
				[showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false" [allowColumnReordering]="false"
				[allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="listHeight">
				<ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
					<resource-list-cell [tplKey]="tplKey" [data]="data" 
					(editResourceClicked)="onResourceEditClicked($event)">
						
					</resource-list-cell>	
				</ng-template>
				</ts-tree-list>
            </div>
		</ng-container>	
	</div>
</div>

<ng-template #resourceTemplate>
	<resource-drawer #resourceDrawer [ownedDevices]="ownedDevices" [resource]="drawerResource" [mode]="drawerMode" 
	[allocableDevices]="freeDevices" [departments]="departments"></resource-drawer>
</ng-template>

<ng-template #resourceDrawerBottomBarTemplate>
	<ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar *ngIf="drawerMode == 'new'" exitLabel="{{'orders-list.save-button' | translate}}" [disableExit]="!resourceDrawer?.formValid"
                   (closePage)="onResourceDrawerSaveClick()">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>	    
	<ts-bottom-bar *ngIf="drawerMode == 'edit'"  exitLabel="{{'orders-list.orderInfo.editButton' | translate}}"
					[disableExit]="!resourceDrawer?.formValid"
                   (closePage)="onResourceDrawerEditClick()">
        <ts-button type="tertiary blue medium" text="{{'orders-list.orderInfo.cancelButton' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>