import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DeviceService } from '../../../services/device.service';
import { UserInstance } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { DeviceInstance } from '../../../models/device.model';
import { PlantInstance } from '../../../models/plant.model';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { Drawer, FontService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';

import { faPencil, faWindowMaximize, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { UserDrawerComponent } from '../../ui/user-drawer/user-drawer.component';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit {

    columns: TsTreeListColumn[] = [];

    isAdmin = false;

    users: UserInstance[];

    tmpUsers: UserInstance[];

    selectedCompany: any;

    selectedCompanyId: number;

    devices: DeviceInstance[];

    plants: PlantInstance[];

    selectedUser: UserInstance;
    usersListHeight = '100vh';

    private _subscriptions: Subscription[] = [];

    @ViewChild('usersList', { static: false, read: ElementRef }) usersListRef!: ElementRef;
    @ViewChild('userInfoTemplate', { static: false }) userInfoTemplate: TemplateRef<any>;
    @ViewChild('newUserTemplate', { static: false }) newUserTemplate: TemplateRef<any>;
    @ViewChild('editUserTemplate', { static: false }) editUserTemplate: TemplateRef<any>;
    @ViewChild('userDrawerDrawerBottomTemplate', { static: false }) userDrawerDrawerBottomTemplate: TemplateRef<any>;
    @ViewChild('userDrawer', { static: false }) userDrawer: UserDrawerComponent;

    constructor(
        private _company: CompanyService,
        private _user: UserService,
        private _device: DeviceService,
        private _drawer: Drawer,
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _popup: PopupService,
        private _translate: TranslateService,
        private _font: FontService,
        public auth: AuthService,
        private _router: Router
    ) {
        this._font.addIcon(faPencil, faWindowMaximize, faTrashAlt);

        // Collapse drawer when the user navigates to another page
        const routerEventSubscription =
            this._router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this._drawer.collapse();
                }
            });
        this._subscriptions.push(routerEventSubscription);
    }

    async ngOnInit() {
        const translationSubscription =
            this._translate.stream([
                'users.title',
                'users.columns.email',
                'users.columns.firstName',
                'users.columns.lastName',
                'users.columns.createAt',
                'users.columns.role',
                'users.columns.enabled',
                'orders-list.search'
            ])
                .subscribe((translations) => {
                    this._navbar.setTitle(translations['users.title']);
                    this._sidebar.setSelected('users');

                    const defaultColumn: TsTreeListColumn = {
                        dataField: '',
                        headerPlaceholder: translations['orders-list.search'],
                        allowFiltering: true,
                        allowEditing: false,
                        allowSorting: false,
                        width: '100%'
                    };

                    this.columns = [
                        { // Email
                            ...defaultColumn,
                            dataField: 'email',
                            caption: translations["users.columns.email"],
                            dataType: 'string',
                        },
                        { // First Name
                            ...defaultColumn,
                            dataField: 'firstName',
                            caption: translations["users.columns.firstName"],
                            dataType: 'string',
                        },
                        { // Last Name
                            ...defaultColumn,
                            dataField: 'lastName',
                            caption: translations["users.columns.lastName"],
                            dataType: 'string',
                        },
                        { // Role
                            ...defaultColumn,
                            dataField: 'role',
                            caption: translations["users.columns.role"],
                            cellTemplate: 'tplRole',
                            dataType: 'string',
                        },
                        { // Plants
                            ...defaultColumn,
                            dataField: 'plant',
                            caption: translations["users.columns.plant"],
                            dataType: 'string',
                            cellTemplate: 'tplPlants',
                            width: 200
                        },
                        { // actions
                            ...defaultColumn,
                            caption: '',
                            width: 135,
                            cellTemplate: 'tplActions',
                            fixedPosition: 'right',
                            alignment: 'center',
                            allowFiltering: false,
                        },
                    ]

                });
        this._subscriptions.push(translationSubscription);

        this.selectedCompanyId = Number(localStorage.getItem('companyId'));
        this.isAdmin = this.auth.user.role === UserRole.admin;
        if (this.isAdmin) {
            await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
        } else {
            this.devices = await this._device.getDevices();
            if (this.devices.length > 0) {
                this.selectedCompany = this.devices[0].Company;
                this.users = await this._user.getUsers();
                this.users = this.users.filter((tag) => {
                    return tag.id > 0;
                });
                this.tmpUsers = this.clone(this.users);
                this.plants = await this.getPlants(this.selectedCompany.id);
            }
        }

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                if (this.isAdmin) {
                    this.companyChanged(company);
                }
            });

        this._subscriptions.push(companyChangeSubscription);
    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.usersListRef) {
            const offsetTop = this.usersListRef.nativeElement.offsetTop;
            this.usersListHeight = `calc(100vh - ${offsetTop}px)`;
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    async companyChanged(company: CompanyInstance) {
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;
        this.plants = await this.getPlants(company.id);
        this.users = await this._user.getUsers(company.id);
        this.users = this.users.filter((user) => {
            return user.id > 0;
        });

        this.tmpUsers = this.clone(this.users);
    }

    /**
     * Retrieve the plant list about company
     *
     * @param {number} companyId
     * @returns
     * @memberof UsersComponent
     */
    async getPlants(companyId: number) {
        return await this._company.getPlants(companyId);
    }

    async deleteUser(user: UserInstance) {
        if (!user.id) {
            return;
        }

        const userIndex = this.tmpUsers.findIndex(u => u.id === user.id);

        const popupConfig: PopupConfig = {
            title: this._translate.instant('users.title'),
            level: 'error',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: {
                primary: {
                    text: this._translate.instant('dialogs.delete.confirm'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.delete.cancel'),
                },
            },
        };
        const bodyConfig: PopupBodyConfig = {
            content: this._translate.instant('users.dialog.text', { prod: user.email }),
        };

        this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {
            try {
                if (result === 'primary') {
                    await this._user.delete(user.id);

                    this.users.splice(userIndex, 1);
                    this.tmpUsers.splice(userIndex, 1);

                    this.users = [...this.users];
                    this.tmpUsers = [...this.tmpUsers];

                    this._ui.showNotification('users.deleted');
                } else {
                    this._ui.showNotification('users.canceled', 'alert');
                    return;
                }
            } catch (error) {
                console.log(error);
                this._ui.showNotification('users.error', 'alert');
                return;
            }
        })

    }

    async save(user: UserInstance) {
        let res = null;

        if (user.id) {
            const userIdx = this.users.findIndex(u => u.id === user.id);
            res = await this._user.update(user);
            if (res === 200) {
                this.users[userIdx] = user;
                this.tmpUsers[userIdx] = user;
                this.updateSelectedUserPlant(user);
                this._ui.showNotification(this._translate.instant('users.saved'));
            } else {
                this._ui.showNotification(this._translate.instant('users.error'), 'alert');
            }
        } else {
            user['companyId'] = this.selectedCompany.id;
            const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
            res = await this._user.create(user, lang);
            if (res) {
                user.id = res.id;
                this.users.unshift(user);
                this.tmpUsers.unshift(user);
                this.updateSelectedUserPlant(user);
                this._ui.showNotification(this._translate.instant('users.saved'));
            } else {
                this._ui.showNotification(this._translate.instant('users.error'), 'alert');
            }
        }

        this.tmpUsers = this.clone(this.users);

        this._drawer.close();

    }

    showCreateOrUpdateUser(userId?: number) {
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        if (userId) {
            this.selectedUser = this.tmpUsers.find(user => user.id === userId);
        }

        this._drawer.open({
            title: this._translate.instant('users.title'),
            subTitle: '',
            expanded: false,
            marginTop: 0,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: userId ? this.editUserTemplate : this.newUserTemplate,
            bottomBarTemplate: this.userDrawerDrawerBottomTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`New User drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });

    }

    onUserInfoClicked(user: UserInstance) {
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        this.selectedUser = user;

        this._drawer.open({
            title: this._translate.instant('users.title'),
            subTitle: user.firstName + ' ' + user.lastName,
            expanded: false,
            marginTop: 0,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: this.userInfoTemplate,
            bottomBarTemplate: this.userDrawerDrawerBottomTemplate
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Operator info drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    onUserFormSubmit() {
        const result = this.userDrawer.onSubmit();
        if (result) {
            this.save(result);
        }
    }

    clone(array: any) {
        return array.map(x => Object.assign({}, x));
    }

    updateSelectedUserPlant(user: UserInstance) {
        user.Plants = [];
        if (user && user.plant && user.plant.id !== -1 && this.plants && this.plants.length > 0) {
            user.Plants[0] = this.plants.find(p => p.id === user.plant.id);
        }
    }

    onCancelDrawerClicked() {
        this._drawer.close();
    }

}
