import { DeviceInstance } from './device.model';
import { TagInstance } from './tag.model';
import { ErrorCategoryInstance } from './error-category.model';

export type ErrorTypeString = '' | 'Planned' | 'Unplanned' | 'Change Over' | 'Not Specified' | 'Disconnection' | 'Non Production';

export enum ErrorType {
    unplanned = 0,
    planned = 1,
    delay = 2
}

export interface ErrorInstance {
    id?: number;
    code?: string;
    text?: string;
    categoryId?: number;
    companyId: number;
    type: ErrorType;
    enabled: boolean;
    count?: number;
    Devices: DeviceInstance[];
    ErrorsTags?: TagInstance[];
    tagId?: number;
    Category?: ErrorCategoryInstance;
    target?: number;
}

export enum SaveChoice {
    applyOne = 'applyOne',
    applyAll = 'applyAll'
}
