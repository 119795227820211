<ng-container *ngIf="useExisting">
    <ts-tree-list [data]="filteredTasks" [columns]="taskColumns" parentKey="groupId" key="id"
                  emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}" actionsColumnCaption=""
                  groupedActionsTooltipText="" emptyStateSecondaryText="" emptyStateSecondaryLink=""
                  [enablePaging]="false" [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                  [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                  [allowRowUpdating]="false" [wordWrapEnabled]="true">
        <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">

            <span *ngIf="tplKey === 'tplActions'">
                <ts-button text="{{'dialogs.devices.select' | translate}}" type="tertiary small blue"
                           (buttonClicked)="onSelectedTask(data.id)"></ts-button>
            </span>
        </ng-template>
    </ts-tree-list>
</ng-container>

<ng-container *ngIf="showForm">
    <ts-tab-panel [panelTabs]="tabs" type="primary" barType="standard" size="small" [(selectedTab)]="selectedTabId"
                  [selectTabOnClick]="true">
    </ts-tab-panel>

    <form [formGroup]="form" [autocomplete]="false" class="container">
        <div *ngIf="selectedTabId === 1">
            <div class="ctd-row">
                <div class="ctd-column">
                    <ts-text-box primaryText="{{'control.dialog.columns.description' | translate}}"
                                 placeholderText="{{'control.dialog.columns.description' | translate}}"
                                 formControlName="description" [showErrors]="true" width="300px"
                                 [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
                </div>
            </div>
            <div class="ctd-column">
                <span class="ts-input-container__header__primary__text">{{'control.dialog.question-type' |
                    translate}}</span>
                <div class="ctd-row">
                    <div class="ctd-column">
                        <div class="ctd-item">
                            <ts-simple-checkbox formControlName="closedQuestion"></ts-simple-checkbox>
                            <span>
                                {{'control.dialog.closed-question' | translate}}
                            </span>
                        </div>
                        <div class="ctd-item">
                            <ts-simple-checkbox formControlName="taskWithValues"></ts-simple-checkbox>
                            <span>
                                {{'control.dialog.open-question' | translate}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column task-parameters" *ngIf="form.get('taskWithValues').value"
                 formArrayName="task_parameters">
                <div *ngFor="let param of _taskParams.controls; let i = index" [formGroupName]="i">
                    <div class="ctd-row">
                        <ts-text-box primaryText="{{'control.dialog.value-description' | translate}} {{i + 1}}"
                                     placeholderText="{{'control.dialog.value-description' | translate}}"
                                     [validationDict]="customValidationDict | translateJSON | async"
                                     formControlName="description" [showErrors]="true"></ts-text-box>
                        <ts-select width="130px" [data]="units" [clearable]="true"
                                   placeholderText="{{'control.dialog.unit' | translate}}" [enabled]="true"
                                   [showErrors]="true" primaryText="{{'control.dialog.unit' | translate}}"
                                   labelKey="description" formControlName="unit"
                                   [value]="param.value.unit?.description || ''"
                                   [validationDict]="customValidationDict | translateJSON | async">
                        </ts-select>
                        <ts-button [type]="'tertiary medium alert'" icon="trash-alt" iconSize="1x"
                                   (buttonClicked)="deleteTaskParameter(i)"></ts-button>
                    </div>
                    <div class="ctd-row">
                        <app-toggle [id]="i"
                                    label="{{'control.dialog.insert-threshold' | translate}}"
                                    [value]="getToleranceThresholdValue(i)"
                                    (onChange)="updateToleranceThresholdValue(i, $event)"></app-toggle>
                    </div>

                    <div *ngIf="param.value.tolerance_threshold" formGroupName="parameters" class="ctd-column"
                         style="margin-top:10px;">
                        <div class="ctd-row">
                            <ts-text-box width="170px" primaryText="{{ 'control.dialog.min-threshold' | translate }}"
                                         placeholderText="{{ 'control.dialog.min-threshold' | translate }}"
                                         [validationDict]="customValidationDict | translateJSON | async"
                                         formControlName="minThreshold" [showErrors]="true">
                            </ts-text-box>
                            <ts-text-box width="170px" primaryText="{{ 'control.dialog.max-threshold' | translate }}"
                                         placeholderText="{{ 'control.dialog.max-threshold' | translate }}"
                                         [validationDict]="customValidationDict | translateJSON | async"
                                         formControlName="maxThreshold" [showErrors]="true">
                            </ts-text-box>
                            <ts-text-box width="170px" primaryText="{{ 'control.dialog.target-value' | translate }}"
                                         placeholderText="{{ 'control.dialog.target-value' | translate }}"
                                         [validationDict]="customValidationDict | translateJSON | async"
                                         formControlName="targetValue" [showErrors]="true">
                            </ts-text-box>
                        </div>

                        <div class="ctd-row">
                            <ts-text-box primaryText="{{'control.dialog.info-popup' | translate}}"
                                         placeholderText="{{'control.dialog.info-popup' | translate}}"
                                         [validationDict]="customValidationDict | translateJSON | async"
                                         formControlName="outOfRangeDescription" [showErrors]="true"></ts-text-box>
                        </div>
                    </div>

                </div>
                <div class="ctd-row" style="margin: 10px 0;">
                    <ts-button text="+ {{'control.dialog.add-value' | translate}}" type="secondary medium blue"
                               (buttonClicked)="addEmptyTaskParameter()"></ts-button>
                </div>
            </div>

        </div>

        <div class="ctd-column" *ngIf="selectedTabId === 2">
            <div class="ctd-column">
                <span class="ts-input-container__header__primary__text">{{'control.dialog.upload-instructions' |
                    translate}}</span>
                <div class="ctd-row">
                    <div class="nested-column">
                        <div class="ctd-item">
                            <ts-simple-radio formControlName="instructionType" [value]="0"></ts-simple-radio>
                            <span>
                                {{'control.dialog.write-note' | translate}}
                            </span>
                        </div>
                        <div class="ctd-item">
                            <ts-simple-radio formControlName="instructionType" [value]="1"></ts-simple-radio>
                            <span>
                                {{'control.dialog.from-file' | translate}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Description only -->

            <div class="ctd-column" *ngIf="form.get('instructionType').value === 0">
                <div class="ctd-column">
                    <vapor-angular-text-area class="text-area"
                                             primaryText="{{'control.dialog.input-instruction' | translate}}"
                                             placeholderText="{{'control.dialog.input-instruction' | translate}}"
                                             [value]="form.get('docText').value" formControlName="docText"
                                             [showErrors]="true" [minLength]="1" [maxLength]="100"
                                             [validationDict]="customValidationDict | translateJSON | async"></vapor-angular-text-area>
                </div>
            </div>

            <!-- File Upload -->
            <div class="ctd-column" *ngIf="form.get('instructionType').value === 1">
                <div class="ctd-column" *ngIf="selectedDocument">
                    <span class="ts-input-container__header__primary__text">{{'control.dialog.selected-file' |
                        translate}}</span>

                    <div class="ctd-row">
                        <ts-icon [name]="['fas', 'check-circle']" color="green" size="1x"></ts-icon>
                        <span>{{ selectedDocument.originalname }}</span>
                    </div>
                </div>

                <div class="ctd-column">
                    <span class="ts-input-container__header__primary__text">Upload File</span>
                    <div class="ctd-row" style="border: 2px dashed #ccc; padding: 20px; text-align: center;">
                        <span>{{'control.dialog.drag-file' | translate}}</span>
                        <label for="fileInput" style="cursor: pointer; color: blue;">{{'control.dialog.upload-file' |
                            translate}}</label>
                        <input type="file" id="fileInput" accept="application/pdf" style="display: none;" #fileInput
                               (change)="handleFileInput($event.target.files)" />
                    </div>
                </div>


                <div class="ctd-column">
                    <span class="ts-input-container__header__primary__text">{{'control.dialog.choose-file' |
                        translate}}</span>

                    <ts-tree-list [data]="docs" [columns]="documentColumns" parentKey="groupId" key="id"
                                  emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                                  [enablePaging]="false" [showSearchBar]="false" [hideActionsColumn]="true"
                                  [showRowLines]="true" [showBorders]="false" [allowRowDeleting]="false"
                                  [allowRowInserting]="false" [allowRowUpdating]="false" [wordWrapEnabled]="true">
                        <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                        <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                            <span *ngIf="tplKey === 'tplActions'">
                                <ts-button text="{{'dialogs.devices.select' | translate}}" type="tertiary small blue"
                                           (buttonClicked)="selectDocument(data)"
                                           [disabled]="selectedDocument && data.id === selectedDocument.id"></ts-button>
                            </span>
                        </ng-template>
                    </ts-tree-list>
                </div>
            </div>
        </div>
    </form>
</ng-container>