import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { DeviceInstance } from '../../../models/device.model';
import { ErrorInstance, ErrorType } from '../../../models/error.model';
import { ErrorCategoryInstance } from '../../../models/error-category.model';

import { DeviceService } from '../../../services/device.service';
import { ErrorService } from '../../../services/error.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';

import { TranslateService } from '@ngx-translate/core';
import { TagInstance } from '../../../models/tag.model';
import { TagsService } from '../../../services/tag.service';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DelaysService } from '../../../services/delays.service';
import { DelayInstance } from '../../../models/delay.model';
import { UserView, ViewService } from '../../../services/view.service';
import { SaveChoice } from '../../../models/error.model';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { Drawer, FontService, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';

import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { ErrorsDrawerComponent } from '../../ui/errors-drawer/errors-drawer.component';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { CompanyService } from '../../../services/company.service';
import { UiService } from '../../../services/ui.service';
import { CompanyInstance } from '../../../models/company.model';

@Component({
    selector: 'app-errors',
    templateUrl: './errors.component.html',
    styleUrls: ['./errors.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ErrorsComponent implements OnInit {
    isDelaysManager = false;

    errorCategories: ErrorCategoryInstance[];
    errorTags: TagInstance[];
    errors: ErrorInstance[];
    planned: ErrorInstance[];
    unplanned: ErrorInstance[];
    delays: DelayInstance[];
    tmpPlanned: ErrorInstance[];
    tmpUnplanned: ErrorInstance[];
    tmpDelays: DelayInstance[];

    devices: DeviceInstance[];
    selectedDevice: DeviceInstance;

    showPlanned = true;
    showDelays = false;
    isAdmin = false;
    plannedEditRow: number;
    unPlannedEditRow: number;

    columns: TsTreeListColumn[] = [];
    delayColumns: TsTreeListColumn[] = [];
    unplannedColumns: TsTreeListColumn[] = [];

    selectedCompanyId: number;

    plannedSearch: string;
    unplannedSearch: string;

    plannedCategoryFilter: ErrorCategoryInstance;
    unplannedCategoryFilter: ErrorCategoryInstance;

    tabs = [];
    selectedTabId: number;

    selectedError: ErrorInstance;
    selectedDelay: DelayInstance;

    selectedCompany: CompanyInstance;

    ErrorType = ErrorType;

    errorsListHeight = '100vh';

    private _subscriptions: Subscription[] = [];

    categoriesFilter: any[] = [];
    groupsFilter: any[] = [];


    @ViewChild('errorsList', { static: false, read: ElementRef }) errorsListRef!: ElementRef;
    @ViewChild('editErrorsTemplate', { static: false }) editErrorsTemplate: TemplateRef<any>;
    @ViewChild('newErrorsTemplate', { static: false }) newErrorsTemplate: TemplateRef<any>;
    @ViewChild('errorsDrawerBottomTemplate', { static: false }) errorsDrawerBottomTemplate: TemplateRef<any>;
    @ViewChild('errorsDrawer', { static: false }) errorsDrawer: ErrorsDrawerComponent;


    constructor(
        private _ui: UiService,
        private _device: DeviceService,
        private _company: CompanyService,
        private _error: ErrorService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _drawer: Drawer,
        private _popup: PopupService,
        private _translate: TranslateService,
        private _errorTag: TagsService,
        private _auth: AuthService,
        private _spinner: OverlaySpinnerService,
        public _viewService: ViewService,
        private _delayService: DelaysService,
        private _font: FontService,
        private readonly _router: Router,

    ) {
        this._font.addIcon(faPencil, faTrashAlt);

        // Collapse drawer when the user navigates to another page
        const routerEventSubscription =
            this._router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this._drawer.collapse();
                }
            });
        this._subscriptions.push(routerEventSubscription);

    }

    async ngOnInit() {
        if (!!this._auth && !!this._auth.user) {
            // check if the current user is an admin
            this.isAdmin = this._auth.user.role === UserRole.admin;

            this._viewService.userCanView(this._auth.user, UserView.delays)
                .then(userCanViewDelays => {
                    this.isDelaysManager = userCanViewDelays;
                });
        }

        this.selectedCompanyId = Number(localStorage.getItem('companyId')) || 1;

        if (this.selectedCompanyId) {
            this.selectedCompany = await this._company.getCompany(this.selectedCompanyId, true);
        }

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                this.companyChanged(company);
            });

        this._subscriptions.push(companyChangeSubscription);

        try {
            const res = (await this._company.getCompany(this.selectedCompanyId, true)).Devices;

            this.devices = res.map(device => ({
                ...device,
                customLabel: `${device.label}`
            }));

            if (this.devices.length > 0) {

                if (localStorage.getItem('deviceId')) {
                    const device = this.devices.find((item) => item.id === Number(localStorage.getItem('deviceId')));
                    if (device) {
                        this.selectedDevice = device
                    } else {
                        this.selectedDevice = this.devices[0]
                    }

                } else {
                    this.selectedDevice = this.devices[0]
                };
            }

            this.errorCategories = await this._error.getErrorCategories(this.selectedCompanyId);

            await this.deviceChanged();

        } catch (error) {
            console.log(error)
        }
    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.errorsListRef) {
            const offsetTop = this.errorsListRef.nativeElement.offsetTop;
            this.errorsListHeight = `calc(100vh - ${offsetTop}px)`;
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    companyChanged(company: CompanyInstance) {
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;
        localStorage.setItem('companyId', company.id.toString());

        this.devices = this.selectedCompany.Devices.map(device => ({ ...device, customLabel: `${device.label}` }));
        this.selectedDevice = this.devices[0];

        this.deviceChanged(this.selectedDevice);
    }

    updateDataTable() {
        this._translate
            .stream([
                'stopReasons.title',
                'stopReasons.columns.priority',
                'stopReasons.columns.code',
                'stopReasons.columns.text',
                'stopReasons.columns.category',
                'stopReasons.columns.target',
                'stopReasons.columns.tag',
                'stopReasons.columns.enabled',
                'stopReasons.columns.devices',
            ])
            .subscribe((translations) => {
                this._navbar.setTitle(translations['stopReasons.title']);
                this._sidebar.setSelected('stops');

                this.tabs = [{
                    id: 1,
                    text: this._translate.instant('stopReasons.planned'),
                    disabled: false,
                }, {
                    id: 0,
                    text: this._translate.instant('stopReasons.unplanned'),
                    disabled: false,
                }, {
                    id: 2,
                    text: this._translate.instant('stopReasons.delays'),
                    disabled: !this.isDelaysManager,
                }]

                this.selectedTabId = 1; // default tab: Planned

                const defaultColumn: TsTreeListColumn = {
                    dataField: '',
                    headerPlaceholder: translations['orders-list.search'],
                    allowFiltering: false,
                    allowEditing: false,
                    allowSorting: false,
                    allowResizing: false,
                    width: '100%'
                }

                this.categoriesFilter = this.errorCategories.map((cat) => ({
                    "label": cat.name,
                    "id": cat.name
                }));

                this.groupsFilter = this.errorTags.map((err) => ({
                    id: err.name,
                    name: err.name,
                }))


                this.columns = [{
                    ...defaultColumn,
                    dataField: 'code',
                    caption: translations["stopReasons.columns.code"],
                    dataType: 'string',
                    allowFiltering: true
                }, {
                    ...defaultColumn,
                    dataField: 'text',
                    caption: translations["stopReasons.columns.text"],
                    dataType: 'string',
                    allowFiltering: true,
                }, {
                    ...defaultColumn,
                    dataField: 'Category.name',
                    caption: translations["stopReasons.columns.category"],
                    dataType: 'string',
                    lkpDataSource: this.categoriesFilter,
                    lkpResultField: 'label',
                    lkpKeyField: 'id',
                    allowFiltering: true,
                    width: 180,
                }, {
                    ...defaultColumn,
                    dataField: 'ErrorsTags[0].name',
                    caption: translations["stopReasons.columns.tag"],
                    dataType: 'object',
                    lkpDataSource: this.groupsFilter,
                    lkpResultField: 'name',
                    lkpKeyField: 'id',
                    allowFiltering: true,
                    cellTemplate: "tplTags"
                }, {
                    ...defaultColumn,
                    dataField: 'target',
                    caption: translations["stopReasons.columns.target"],
                    dataType: 'string',
                }, {
                    ...defaultColumn,
                    dataField: 'Devices',
                    caption: translations["stopReasons.columns.devices"],
                    dataType: 'string',
                    cellTemplate: 'tplDevices'
                }, {
                    ...defaultColumn,
                    dataField: 'enabled',
                    caption: translations["stopReasons.columns.enabled"],
                    dataType: 'boolean',
                    width: 100,
                    name: translations['stopReasons.columns.enabled'],
                },
                { // actions
                    ...defaultColumn,
                    caption: '',
                    fixedPosition: 'right',
                    alignment: 'center',
                    width: 135,
                    allowFiltering: false,
                    allowResizing: false,
                    allowFixing: false,
                    cellTemplate: 'tplActions',
                }];

                this.unplannedColumns = this.columns.filter((col) => col.dataField !== 'target');

                if (this.isAdmin) {
                    this.columns.splice(0, 0,
                        {
                            ...defaultColumn,
                            dataField: 'id',
                            caption: translations["stopReasons.columns.priority"],
                            dataType: 'string',
                            cellTemplate: 'tplPriority',
                            width: 100,
                        })

                    this.unplannedColumns.splice(0, 0, {
                        ...defaultColumn,
                        dataField: 'id',
                        caption: translations["stopReasons.columns.priority"],
                        dataType: 'string',
                        cellTemplate: 'tplPriority',
                        width: 100,
                    })
                }

                this.delayColumns = [{
                    ...defaultColumn,
                    dataField: 'description',
                    caption: translations["stopReasons.columns.text"],
                    dataType: 'string',
                }, {
                    ...defaultColumn,
                    dataField: 'Devices',
                    caption: translations["stopReasons.columns.devices"],
                    dataType: 'object',
                    cellTemplate: "tplDevices",
                }, {
                    ...defaultColumn,
                    dataField: 'enabled',
                    caption: translations["stopReasons.columns.enabled"],
                    dataType: 'boolean'
                }, { // actions
                    ...defaultColumn,
                    caption: '',
                    fixedPosition: 'right',
                    alignment: 'center',
                    width: 135,
                    allowFiltering: false,
                    allowResizing: false,
                    allowFixing: false,
                    cellTemplate: 'tplActions',
                }];
            });
    }


    showCreateOrUpdateDrawer(type: ErrorType, _id?: number) {
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        if (_id) {
            if (type === 0 || type === 1) {
                this.selectedError = this.errors.find(e => e.id === _id);
                this.selectedDelay = null
            } else {
                this.selectedError = null;

                this.selectedDelay = this.tmpDelays.find(d => d.id === _id);

                if (this.selectedDelay.Devices.length === 0) {
                    this.selectedDelay.Devices = [this.selectedDevice];
                }
            }
        }

        this._drawer.open({
            title: this._translate.instant('stopReasons.title'),
            subTitle: '',
            expanded: false,
            marginTop: 0,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: _id ? this.editErrorsTemplate : this.newErrorsTemplate,
            bottomBarTemplate: this.errorsDrawerBottomTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`New Error drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    async deviceChanged(device?: DeviceInstance) {
        this._spinner.show();

        if (device) {
            this.selectedDevice = device;
        }

        localStorage.setItem('deviceId', this.selectedDevice?.id.toString());

        if (this.isAdmin && this.selectedDevice) {
            this.errorTags = await this._errorTag.getTags(true, this.selectedCompanyId);
        } else {
            this.errorTags = await this._errorTag.getTags();
        }

        this.errorTags = this.errorTags.filter((event) => {
            return event.id > 0;
        });

        this.errorTags.unshift({
            id: 0,
            name: '-- none --'
        });

        this.errors = await this._error.getErrors(this.selectedDevice.id);
        this.planned = [];
        this.unplanned = [];
        this.delays = [];

        if (this.isDelaysManager) {
            this.delays = await this._delayService.getDelays(this.selectedDevice.id);
        }

        this.errors.forEach((e) => {
            if (e.ErrorsTags.length === 0) {
                e.ErrorsTags.push({
                    id: 0
                });
            }
            e.tagId = e.ErrorsTags[0].id;
            if (e.Category.type === ErrorType.planned) {
                this.planned.push(e);
            } else {
                this.unplanned.push(e);
            }
        });
        this.tmpPlanned = this.cloneArray(this.planned);
        this.tmpUnplanned = this.cloneArray(this.unplanned);
        this.tmpDelays = this.cloneArray(this.delays);

        this.updateDataTable();

        this._spinner.removeOverlay();
    }


    async updatePriorityError(errorId: number) {
        this.selectedDevice.priorityErrorId = errorId;
        await this._device.updatePriorityError(errorId, this.selectedDevice.id);
    }

    async saveError(err) {

        let res = null;
        let dialogResult = null;

        try {
            if (err.Devices.length > 1) {
                const popupConfig: PopupConfig = {
                    title: this._translate.instant('dialogs.errors.applychangestoalldevices_info_header'),
                    level: 'info',
                    showCloseButton: true,
                    visible: true,
                    dragEnabled: false,
                    bottomButtons: {
                        secondary: {
                            text: this._translate.instant('dialogs.errors.applyAll'),
                        },
                        primary: {
                            text: this._translate.instant('dialogs.errors.applyOne'),
                        },
                        tertiary: {
                            text: this._translate.instant('dialogs.errors.back'),
                        },
                    },
                };

                const bodyConfig: PopupBodyConfig = {
                    content: this._translate.instant('dialogs.errors.applychangestoalldevices_info_text', {
                        deviceName: this.selectedDevice.label,
                    }),
                };

                dialogResult = await new Promise<SaveChoice | null>((resolve) => {
                    this._popup.show(popupConfig, bodyConfig).subscribe((result) => {
                        if (result === 'secondary') {
                            resolve(SaveChoice.applyAll);
                        } else if (result === 'primary') {
                            resolve(SaveChoice.applyOne);
                        } else {
                            resolve(null);
                        }
                    });
                });

                if (dialogResult === null) {
                    return;
                }
            }

            if (err.id) {
                res = await this._error.updateError(err, dialogResult, this.selectedDevice);
            } else {
                res = await this._error.saveNewError(err, dialogResult, this.selectedDevice.id);
                err.id = res.id;
            }

            if (err.tagId !== err.ErrorsTags[0].id) {
                if (!err.ErrorsTags[0].id && err.tagId) {
                    await this._error.addErrorTag(err.id, err.tagId);
                } else if (!err.tagId && err.ErrorsTags[0].id) {
                    await this._error.removeErrorTag(err.id, err.ErrorsTags[0].id);
                } else {
                    await this._error.updateErrorTag(err.id, err.ErrorsTags[0].id, err.tagId);
                }
            }
            this._drawer.close();
            this._ui.showNotification(this._translate.instant('stopReasons.saved'), 'check');
            await this.deviceChanged();

        } catch (error) {
            this._ui.showNotification(this._translate.instant('stopReasons.saveErrorError'), 'error');
            console.log(error)
        }
    }

    handleTabChange(tabId: number) {
        this.selectedTabId = tabId;
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }
    }

    onFormSubmit() {
        const res = this.errorsDrawer.onSubmit();

        if (res.isEditing && (res.type === 1 || res.type === 0)) {
            // Editing and planned or unplanned
            this.selectedError = {
                ...this.selectedError,
                ...res.data
            }

            this.saveError(this.selectedError);

        } else if (!res.isEditing && (res.type === 1 || res.type === 0)) {
            // Not Editing and planned or unplanned
            const data = {
                ...res.data,
                ErrorsTags: [{ id: 0 }]
            }

            this.saveError(data)
        } else if (res.isEditing && res.type === 2) {
            // Editing and delay
            this.selectedDelay = {
                ...this.selectedDelay,
                ...res.data
            }
            this.saveDelay(this.selectedDelay)
        } else {
            // New Delay
            const delay_data = {
                companyId: this.selectedCompanyId,
                plantId: this.selectedDevice.plantId,
                ...res.data
            }

            this.saveDelay(delay_data)
        }

    }

    filterPlanned() {
        const tmp = this.planned.filter((d) => {
            if (this.plannedCategoryFilter) {
                if (d.categoryId !== this.plannedCategoryFilter.id) return false;
            }

            if (this.plannedSearch && this.plannedSearch.trim() !== '') {
                if (d.text.toLowerCase().indexOf(this.plannedSearch.toLowerCase()) === -1) {
                    return false;
                }
            }

            return true;
        });

        this.tmpPlanned = [...tmp];
    }

    async deleteError(err: ErrorInstance) {
        if (!err.id) {
            if (this.selectedTabId === 1) {
                this.tmpPlanned = this.tmpPlanned.filter(e => e.id !== err.id);
            } else {
                this.tmpUnplanned = this.tmpUnplanned.filter(e => e.id !== err.id);
            }
            return;
        }

        const popupConfig: PopupConfig = {
            title: this._translate.instant('stopReasons.dialog.title'),
            level: 'error',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: {
                primary: {
                    text: this._translate.instant('dialogs.delete.confirm'),
                },
                secondary: {
                    text: this._translate.instant('dialogs.delete.disable'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.delete.cancel'),
                },
            },
        };

        const bodyConfig: PopupBodyConfig = {
            content: this._translate.instant('stopReasons.dialog.text', { err: err.text, count: err.count || 0 })
        };

        this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {
            if (result === 'primary') {
                await this._error.deleteError(err.id);

                if (this.selectedTabId === 1) {
                    this.tmpPlanned = this.tmpPlanned.filter(e => e.id !== err.id);
                } else {
                    this.tmpUnplanned = this.tmpUnplanned.filter(e => e.id !== err.id);
                }

                this._ui.showNotification(this._translate.instant('stopReasons.deleted'), 'check');
            } else if (result === 'secondary') {
                err.enabled = false;
                await this._error.updateError(err);

                // Set as disabled
                if (this.selectedTabId === 1) {
                    this.tmpPlanned = this.tmpPlanned.map(e => e.id === err.id ? err : e);
                } else {
                    this.tmpUnplanned = this.tmpUnplanned.map(e => e.id === err.id ? err : e);
                }

                this._ui.showNotification(this._translate.instant('stopReasons.disabled'), 'check');

            }

        });
    }

    cloneArray(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }
    cloneObject(object: any) {
        return Object.assign({}, object);
    }

    // Delay functions

    async saveDelay(delay) {

        try {
            let res = null;

            if (delay.id) {
                res = await this._delayService.updateDelay(delay);
                this.tmpDelays = this.tmpDelays.map(d => d.id === res.id ? res : d);
            } else {
                res = await this._delayService.saveNewDelay(delay);
                delay.id = res.id;
                this.tmpDelays = [res, ...this.tmpDelays];
            }

            this._ui.showNotification(this._translate.instant('stopReasons.saveDelay'), 'check');
        } catch (error) {
            console.log(error);
            this._ui.showNotification(this._translate.instant('stopReasons.saveDelayError'), 'error');
        } finally {
            this._drawer.close();
        }
    }

    async deleteDelay(delay: DelayInstance) {

        if (!delay.id) {
            return;
        }

        const popupConfig: PopupConfig = {
            title: this._translate.instant('stopReasons.dialog.delays'),
            level: 'error',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: {
                primary: {
                    text: this._translate.instant('dialogs.delete.confirm'),
                },
                secondary: {
                    text: this._translate.instant('dialogs.delete.disable'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.delete.cancel'),
                },
            },
        };

        const bodyConfig: PopupBodyConfig = {
            content: this._translate.instant('stopReasons.dialog.description', { err: delay.description || 0 })
        };

        this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {
            if (result === 'primary') {
                try {
                    const res = await this._delayService.deleteDelay(delay.id);
                    if (res.status === 200) {
                        // Remove from table
                        this.tmpDelays = this.tmpDelays.filter(d => d.id !== delay.id);
                        this._ui.showNotification(this._translate.instant('stopReasons.deleteDelay'), 'check');
                    }
                } catch (error) {
                    console.log(error);
                    this._ui.showNotification(this._translate.instant('stopReasons.deleteDelayError'), 'error');
                }
            } else if (result === 'secondary') {
                try {
                    delay.enabled = false;
                    await this._delayService.updateDelay(delay);
                    this.tmpDelays = this.tmpDelays.map(d => d.id === delay.id ? delay : d);

                    this._ui.showNotification(this._translate.instant('stopReasons.disableDelay'), 'check');
                } catch (error) {
                    console.log(error);
                    this._ui.showNotification(this._translate.instant('stopReasons.disableDelayError'), 'error');
                }

            }

        });

    }

    onCancelDrawerClicked() {
        this._drawer.close();
    }

}
