import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { Drawer, FontService, NotificationStyle, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';
import { ColumnFilterOperation, ColumnFilterTargetValues, TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { faBox, faCopy, faEllipsisV, faPencil, faPlus, faTrash, faWindowMaximize } from '@fortawesome/pro-regular-svg-icons';
import { faBoxFull } from '@fortawesome/pro-duotone-svg-icons';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { CompanyService } from '../../../services/company.service';
import { NavbarService } from '../../../services/navbar.service';
import { ProductService } from '../../../services/product.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';

import { CompanyInstance } from '../../../models/company.model';
import { DeviceInstance } from '../../../models/device.model';
import { PlantInstance } from '../../../models/plant.model';
import { ProductInterface, ProductType } from '../../../models/product.model';
import { UserRole } from '../../../models/user.model';
import { VariationsService } from '../../../services/variations.service';
import { VariationGroupsInstance } from '../../../models/variation.model';

interface ProductInterfaceUi extends ProductInterface {
    variationGroup1?: VariationGroupsInstance,
    variationGroup2?: VariationGroupsInstance,
    variationGroup3?: VariationGroupsInstance,
}
@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ProductsComponent implements OnInit {
    isAdmin: boolean;
    deleteProductIdSelected: number;
    optionsProductIdSelected: number = null;
    products: ProductInterfaceUi[];
    productsColumns: TsTreeListColumn[] = [];
    productsListHeight = '100vh';
    selectedCompany: CompanyInstance;
    selectedCompanyId: number;
    selectedProduct: ProductInterface;
    typeOptions: any[];
    unitOptions: any[];
    actionTooltipOpening: boolean = false;
    _subscriptions: Subscription[] = [];
    variationsGroups: { id: number, code: string, description: string }[] = [];

    @ViewChild('infoProductTemplate', { static: false }) infoProductTemplate: TemplateRef<any>;
    @ViewChild('infoProductBottomBarTemplate', { static: false }) infoProductBottomBarTemplate: TemplateRef<any>;
    @ViewChild('productsList', { static: false, read: ElementRef }) productsListRef!: ElementRef;
    @ViewChild('newProductTooltip', { static: false, read: ElementRef }) newProductTooltip!: ElementRef;
    @ViewChild('actionsProductTooltip', { static: false, read: ElementRef }) actionsProductTooltip!: ElementRef;
    @ViewChild('addArticleButton', { static: false, read: ElementRef }) addArticleButton!: ElementRef;

    showNewProductTooltips: boolean = false;

    constructor(
        private _ui: UiService,
        private _auth: AuthService,
        private _company: CompanyService,
        private _drawer: Drawer,
        private _font: FontService,
        private _navbar: NavbarService,
        private _product: ProductService,
        private _router: Router,
        private _sidebar: SidebarService,
        private _spinner: OverlaySpinnerService,
        private _translate: TranslateService,
        private _popup: PopupService,
        private _variations: VariationsService,
    ) {
        this._font.addIcon(faCopy, faWindowMaximize, faPencil, faPlus, faEllipsisV, faTrash, faBox, faBoxFull);
    }

    async ngOnInit() {
        const translationSubscription =
            this._translate.stream([
                'products.title',
                'products.search',
                'products.type.product',
                'products.type.semiFinished',
                'products.type.rawMaterial',
                'products.columns.code',
                'products.columns.product-description',
                'products.columns.unit',
                'products.columns.family',
                'products.columns.type',
                'products.columns.devices'
            ]).subscribe((translations) => {
                this._navbar.setTitle(translations['products.title']);
                this._sidebar.setSelected('products');
                this.typeOptions = [
                    {
                        "id": ProductType.product,
                        "label": translations['products.type.product']
                    },
                    {
                        "id": ProductType.semiFinished,
                        "label": translations['products.type.semiFinished']
                    },
                    {
                        "id": ProductType.rawMaterial,
                        "label": translations['products.type.rawMaterial']
                    }
                ];
                const defaultColumn: TsTreeListColumn = {
                    dataField: '',
                    headerPlaceholder: translations['products.search'],
                    allowFiltering: true,
                    allowEditing: false,
                    allowSorting: true
                };
                this.productsColumns =
                    [
                        { // id
                            caption: 'id',
                            dataField: 'id',
                            showColumn: false,
                        },
                        { // code
                            ...defaultColumn,
                            caption: translations['products.columns.code'],
                            dataField: 'code',
                            dataType: 'string',
                            width: 250,
                        },
                        { // description
                            ...defaultColumn,
                            caption: translations['products.columns.product-description'],
                            dataField: 'description',
                            dataType: 'string',
                        },
                        { // unit
                            ...defaultColumn,
                            caption: translations['products.columns.unit'],
                            dataField: 'ProductionUnit.unit',
                            dataType: 'object',
                            lkpDataSource: this.unitOptions,
                            lkpKeyField: 'id',
                            lkpResultField: 'label',
                            width: 150,
                        },
                        { // family
                            ...defaultColumn,
                            caption: translations['products.columns.family'],
                            dataField: 'ProductsFamily.code',
                            dataType: 'string',
                            width: 170,
                        },
                        { // type
                            ...defaultColumn,
                            caption: translations['products.columns.type'],
                            dataField: 'type',
                            dataType: 'object',
                            lkpDataSource: this.typeOptions,
                            lkpKeyField: 'id',
                            lkpResultField: 'label',
                            width: 170,
                        },
                        { // device
                            ...defaultColumn,
                            caption: translations['products.columns.devices'],
                            dataField: 'deviceLabels',
                            dataType: 'object',
                            cellTemplate: 'tplDevices',
                            width: 230,
                            calculateFilterExpression: this._productsDevicesFilter,
                        },
                        { // actions
                            ...defaultColumn,
                            caption: '',
                            fixedPosition: 'right',
                            alignment: 'center',
                            width: 135,
                            allowFiltering: false,
                            allowResizing: false,
                            allowFixing: false,
                            allowSorting: false,
                            cellTemplate: 'tplActions',
                        },
                    ];
            });
        this._subscriptions.push(translationSubscription);

        this.isAdmin = this._auth.user.role === UserRole.admin;
        this.selectedCompanyId = Number(localStorage.getItem('companyId'));
        if (this.isAdmin) {
            await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
        } else {
            this.selectedCompany = await this._company.getCompany(this.selectedCompanyId);
            this.getProducts(this.selectedCompany.Plants?.[0], true);
        }

        this.variationsGroups = (await this._variations.getVariationsGroups(this.selectedCompanyId)).map(v => { return { id: v.id, code: v.code, description: v.description } });

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                if (this.isAdmin) {
                    this.companyChanged(company);
                }
            });
        this._subscriptions.push(companyChangeSubscription);
    }

    async companyChanged(company: CompanyInstance) {
        if (!company) {
            return;
        }
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;
        this.variationsGroups = (await this._variations.getVariationsGroups(this.selectedCompanyId)).map(v => { return { id: v.id, code: v.code, description: v.description } });
        this.getProducts(this.selectedCompany.Plants?.[0], true);
    }

    async getProducts(plant: PlantInstance, showSpinner: boolean) {

        if (showSpinner) this._spinner.show();
        try {
            const products = await this._product.getProductsV2(this.selectedCompanyId);
            this.products = products.map(
                productInstance => {
                    const product: ProductInterfaceUi = {
                        ...productInstance,
                        deviceLabels: productInstance.Devices?.map((device: DeviceInstance) => device.label),
                        variationGroup1: this.variationsGroups.find(v => v.id === productInstance.variationGroupId1),
                        variationGroup2: this.variationsGroups.find(v => v.id === productInstance.variationGroupId2),
                        variationGroup3: this.variationsGroups.find(v => v.id === productInstance.variationGroupId3),
                    };
                    return product;
                }
            );

            const uniqueProductionUnits = [...new Set(this.products.map(item => item.ProductionUnit.unit))].map(x => { return { "id": x, "label": x }; });
            const unitOptions = {
                lkpDataSource: uniqueProductionUnits
            }
            this.productsColumns = this.productsColumns.map(col => {
                return {
                    ...col,
                    ...col.dataField == "ProductionUnit.unit" ? unitOptions : {}
                };
            });
        } catch (err) {
            console.warn(err);
        } finally {
            if (showSpinner) this._spinner.removeOverlay();
        }
    }

    _productsDevicesFilter(filterValue: unknown,
        selectedFilterOperation: ColumnFilterOperation,
        target: ColumnFilterTargetValues) {
        return ["deviceLabels", "contains", filterValue];
    }

    createNewRowMaterial(): void {
        this._router.navigate(['/products/newbuy']);
    }

    createNewProduct(): void {
        this._router.navigate(['/products/newmake']);
    }

    onNewProductClick(): void {
        this.showNewProductTooltips = !this.showNewProductTooltips;
    }

    onInfoProductClicked(product: ProductInterface): void {
        if (this._drawer.isOpened()) this._drawer.close();

        this.selectedProduct = product;

        this._drawer.open({
            title: `${this._translate.instant('products.productTitle')} - ${product.code}`,
            expanded: false,
            marginTop: 0,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: this.infoProductTemplate,
            bottomBarTemplate: this.infoProductBottomBarTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            this._drawer.expand();
        });
    }

    onEditProductClicked(product: ProductInterface): void {
        this._router.navigate([`/products/edit/${product.id}`]);
    }

    onOptionsProductClicked(product: ProductInterface): void {
        this.actionTooltipOpening = true;
        this.optionsProductIdSelected = product.id;
    }

    onEditDrawerClicked(product: ProductInterface): void {
        this._drawer.collapse();
        this.onEditProductClicked(product);
    }

    onDuplicateProductClicked(product: ProductInterface): void {
        this._router.navigate([`/products/duplicate/${product.id}`]);
    }

    onDeleteProductClicked(product: ProductInterface): void {
        this.deleteProductIdSelected = product.id;

        try {
            const popupConfig: PopupConfig = {
                title: this._translate.instant('products.modal.deleteProduct'),
                level: 'error',
                showCloseButton: true,
                visible: true,
                dragEnabled: false,
                bottomButtons: {
                    primary: {
                        text: this._translate.instant('dialogs.del'),
                    },
                    tertiary: {
                        text: this._translate.instant('dialogs.cancel'),
                    },
                },
            };

            const bodyConfig: PopupBodyConfig = {
                content: this._translate.instant('products.modal.deleteMessage', { code: product.code })
            };

            this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {
                if (result === 'primary') {
                    const res = await this._product.deleteById(this.deleteProductIdSelected);
                    const deleted = res === 200;
                    this.products = this.products.filter(product => product.id !== this.deleteProductIdSelected);
                    const { title, message, style } = this.createToast({ id: this.deleteProductIdSelected, deleted: deleted });
                    this._ui.showNotification(message, style, title)
                } else {
                    return;
                }
            })
        } catch (error) {
            const { title, message, style } = this.createToast({ id: this.deleteProductIdSelected, deleted: false });
            this._ui.showNotification(message, style, title)
        }
    }

    onCancelDrawerClicked(): void {
        this._drawer.collapse();
    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.productsListRef) {
            const offsetTop = this.productsListRef.nativeElement.offsetTop;
            this.productsListHeight = `calc(100vh - ${offsetTop}px)`;
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    createToast(productDeleted: { id: number, deleted: boolean }): { title: string, message: string, style: NotificationStyle } {
        let title: string;
        let message: string;
        let style: NotificationStyle = productDeleted?.deleted ? 'check' : 'error';

        if (productDeleted?.deleted === true) {
            message = this._translate.instant('products.productDeletedMessage', { id: productDeleted.id });
            title = this._translate.instant('products.productDeletedTitle');
        } else if (productDeleted?.deleted === false) {
            message = this._translate.instant('products.productDeletedAbortMessage');
            title = this._translate.instant('products.productDeletedAbortTitle');
        }

        return { title, message, style }
    }

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
        if (!this.newProductTooltip.nativeElement.contains(event.target) && !this.addArticleButton.nativeElement.contains(event.target)) {
            this.showNewProductTooltips = false;
        }
        if (this.optionsProductIdSelected !== null && !this.actionTooltipOpening) {
            this.actionTooltipOpening = false;
            this.optionsProductIdSelected = null;
        }
        if (this.actionTooltipOpening) {
            this.actionTooltipOpening = false;
        }
    }
}
