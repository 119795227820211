import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, SecurityContext } from '@angular/core';
import { NavbarService } from '../../../services/navbar.service';
import { ProductService } from '../../../services/product.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { FontService, NotificationStyle, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupService, SimpleSelectComponent } from '@vapor/angular-ui';
import { faArrowLeft, faArrowToBottom, faArrowToTop, faInfoSquare, faPlusCircle, faTimesOctagon, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Resource } from '../../../../../src/app/models/resources.model';
import { Product, ProductInterface, ProductSupplyType, ProductType } from '../../../../../src/app/models/product.model';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { PlantInstance } from '../../../models/plant.model';
import { CompanyService } from '../../../services/company.service';
import { Subscription } from 'rxjs';
import { CompanyInstance } from '../../../models/company.model';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DeviceService } from '../../../services/device.service';
import { ProductionUnitsService } from '../../../services/productionUnits.service';
import { ProductFamilyService } from '../../../services/productFamily.service';
import { VariationsService } from '../../../services/variations.service';
import { GenericDialogComponentData, GenericDialogComponentModes } from '../../ui/generic-dialog/generic-dialog.component';
import { JsonTranslatorPipe } from '../../../pipes/json-translator.pipe';
import { DomSanitizer } from '@angular/platform-browser';

enum menus {
    Registry = 1,
    advancedInfo = 2,
    AssociatedResources = 3,
}

@Component({
    selector: 'app-new-product',
    templateUrl: './new-product.component.html',
    styleUrls: ['./new-product.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        JsonTranslatorPipe
    ],
})

export class NewProductComponent implements OnInit {
    @ViewChild('accordion', { static: true, read: ElementRef }) accordion!: ElementRef;
    @ViewChild('right_wrp', { static: true, read: ElementRef }) rightWrp!: ElementRef<HTMLDivElement>;
    @ViewChild('dividerA', { static: true, read: ElementRef }) advancedInfoDivider!: ElementRef<HTMLElement>;
    @ViewChild('dividerB', { static: true, read: ElementRef }) resourcesDivider!: ElementRef<HTMLElement>;
    @ViewChild('resourcesSelect', { static: false }) resourcesSelect!: SimpleSelectComponent;
    @ViewChild('variationsSelect', { static: false }) variationsSelect!: SimpleSelectComponent;

    isAdmin: boolean;
    private _subscriptions: Subscription[] = [];
    selectedCompanyId: number;
    company: CompanyInstance;
    selectedCompany: CompanyInstance;
    plants: PlantInstance[] = [];
    selectedPlant: PlantInstance;
    selectedPlantId: number;
    resources: { id: number, val: string }[] = [];
    selectedResources: { id: number, val: string, collapsed: boolean }[] = [];
    products: ProductInterface[];
    modalData: GenericDialogComponentData;
    productCreationInfo: ProductInterface = null;
    isDuplicate: boolean = false;
    isDuplicateHasErrors: { code: boolean, description: boolean } = { code: false, description: false };
    isEditing: boolean = false;
    minMultiplierValue = 0.1;

    type: ProductType;
    productSupplyType: ProductSupplyType;
    resource: Resource[] = [];
    infoItems: { description: string, value: string }[] = [];
    variationsGroups: { id: number, val: string }[] = [];
    selectedVariationsGroup: { id: number, val: string }[] = [];

    registryForm: FormGroup;
    additionalInfoForm: FormGroup;
    advancedInfoForm: FormGroup;
    resourcesForm: FormGroup;
    additionalInfoOpen: boolean = false;
    absoluteValuesOpen: boolean = false;
    autoscrolling: boolean = false;
    anchorSet: boolean = false;
    registryAnchorHeight = 0;
    resourcesScroolHeight = 0;
    advancedInfoScroolHeight = 0;
    selectedMenu: menus = menus.Registry;
    skipResourceHandler: boolean = false;

    selectProductTypeData: { id: number, val: string }[] = [];
    selectProductFamiliesData: { id: number, val: string }[] = [];
    selectProductMesureUnitsData: { id: number, val: string }[] = [];

    codeValueOriginal: string;
    descriptionValueOriginal: string;
    selectProductTypeDataValue: { id: number, val: string };
    selectProductFamiliesDataValue: { id: number, val: string };
    selectProductMesureUnitsDataValue: { id: number, val: string };

    customValidationDict = {
        required: 'common.inputErrors.required',
        min: 'common.inputErrors.min',
        notInteger: 'common.inputErrors.notInteger',
    };
    constructor(
        private _ui: UiService,
        private _product: ProductService,
        private _auth: AuthService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _translate: TranslateService,
        private _font: FontService,
        private readonly _fb: FormBuilder,
        private _router: Router,
        private readonly _route: ActivatedRoute,
        private _spinner: OverlaySpinnerService,
        private _popup: PopupService,
        private _device: DeviceService,
        private _company: CompanyService,
        private _productionUnits: ProductionUnitsService,
        private _families: ProductFamilyService,
        private _sanitizer: DomSanitizer,
        private _variations: VariationsService
    ) {
        this._font.addIcon(faArrowLeft, faArrowToBottom, faArrowToTop, faArrowUp, faArrowDown, faInfoSquare, faTrashAlt, faTimesOctagon, faPlusCircle);
    }

    async ngOnInit() {
        try {
            this._spinner.show();

            const translationSubscription = this._translate.stream([
                'products.title',
                'products.type.product',
                'products.type.semiFinished',
                'products.type.rawMaterial',
            ]).subscribe((translations) => {
                this._navbar.setTitle(translations['products.title']);
                this.selectProductTypeData = [
                    { id: ProductType.product, val: translations['products.type.product'] },
                    { id: ProductType.semiFinished, val: translations['products.type.semiFinished'] },
                ]
                this._sidebar.setSelected('products');
            });

            this._subscriptions.push(translationSubscription);

            this.productSupplyType = this._route.snapshot.data.supplyType;
            this.isDuplicate = this._route.snapshot.data.isDuplicate;
            this.isEditing = this._route.snapshot.data.isEditing;

            const id = parseInt(this._route.snapshot.paramMap.get('id'), 10);
            if (this.isDuplicate || this.isEditing) {
                try {
                    this.productCreationInfo = await this._product.getProduct(id);
                } catch (err) {
                    this._ui.showNotification(this._translate.instant('common.loadError'), 'alert');
                }
                this.productSupplyType = (
                    this.productCreationInfo.type == ProductType.product ||
                    this.productCreationInfo.type == ProductType.semiFinished
                ) ? ProductSupplyType.make : ProductSupplyType.buy;
            }

            if (this.productSupplyType == ProductSupplyType.make) {
                this.registryForm = this._fb.group({
                    type: [null, [Validators.required]],
                    code: [null, [Validators.required]],
                    family: [null],
                    measure: [null, [Validators.required]],
                    description: [null, [Validators.required]],
                });
            } else if (this.productSupplyType == ProductSupplyType.buy) {
                this.registryForm = this._fb.group({
                    code: [null, [Validators.required]],
                    family: [null],
                    measure: [null, [Validators.required]],
                    description: [null, [Validators.required]],
                });
            } else {
                this._router.navigate(['/products']);
                return;
            }

            this.additionalInfoForm = this._fb.group({
                keyValueArray: this._fb.array([])
            });

            this.advancedInfoForm = this._fb.group({
                variationsGroupArray: [[]],
                batchEnabled: [false, [Validators.required]],
                serialNumberEnabled: [false, [Validators.required]]
            });

            this.resourcesForm = this._fb.group({
                resourcesFormArray: this._fb.array([])
            });

            this.selectedCompanyId = parseInt(localStorage.getItem('companyId'));
            this.selectedPlantId = parseInt(localStorage.getItem('plantId'));
            this.isAdmin = this._auth.user.role === UserRole.admin;
            this.selectedCompanyId = Number(localStorage.getItem('companyId'));

            if (this.isAdmin) {
                await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
            } else {
                this.selectedCompany = await this._company.getCompany(this.selectedCompanyId);
                this.plants = this.selectedCompany.Plants.map(plant => {
                    return {
                        ...plant,
                        val: plant.description
                    }
                });
                await this._handleBuildPlantArrayObject(true);
                await this.plantChanged(this.selectedPlant, false);
            }

            const companyChangeSubscription = this._company.changeCompanyEmitted$.subscribe(company => {
                this.companyChanged(company);
            });

            this._subscriptions.push(companyChangeSubscription);
            //TODO: should be disabled only if 3 variations are selected
            this.variationsSelect.enabled = false;

            await this.loadData(false);
            this.handleProductCreationInfo();
        } catch (err) {
            console.error(err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    ngAfterContentChecked(): void {
        if (!this.anchorSet) {
            this.setScollsHeight();
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    integerValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (value !== null && !Number.isInteger(value)) {
            return { notInteger: true };
        }

        return null;
    }

    handleProductCreationInfo(): void {
        if (this.productCreationInfo) {
            console.log(this.productCreationInfo, "Info product")
            this.codeValueOriginal = this.isDuplicate && this.productCreationInfo.code;
            this.registryForm.patchValue({ 'code': this.productCreationInfo.code });
            this.descriptionValueOriginal = this.isDuplicate && this.productCreationInfo.description;
            this.registryForm.patchValue({ 'description': this.productCreationInfo.description });
            this.selectProductTypeDataValue = this.selectProductTypeData.find(x => { return this.productCreationInfo.type == x.id });
            this.registryForm.patchValue({ 'type': this.selectProductTypeDataValue });
            this.selectProductMesureUnitsDataValue = this.selectProductMesureUnitsData.find(x => { return this.productCreationInfo.ProductionUnit?.id == x.id });
            this.registryForm.patchValue({ 'measure': this.selectProductMesureUnitsDataValue });
            this.selectProductFamiliesDataValue = this.selectProductFamiliesData.find(x => { return this.productCreationInfo.ProductsFamily?.id == x.id });
            this.registryForm.patchValue({ 'family': this.selectProductFamiliesDataValue });
            this.advancedInfoForm.patchValue({ 'batchEnabled': this.productCreationInfo.batchEnabled });
            this.advancedInfoForm.patchValue({ 'serialNumberEnabled': this.productCreationInfo.serialNumberEnabled });

            //Additional info
            const tempObj = JSON.parse(this.productCreationInfo.jsonInfo);
            this.keyValueArray.clear();
            for (let i = 0; tempObj && i < Object.keys(tempObj).length; i++) {
                const key = `${Object.keys(tempObj)[i]}`;
                const value = `${tempObj[key]}`;
                this.keyValueArray.push(this._fb.group({
                    key: [key, Validators.required],
                    value: [value, Validators.required]
                }));
            }

            const variationsGroups = this.variationsGroups.filter(vg => [this.productCreationInfo.variationGroupId1, this.productCreationInfo.variationGroupId2, this.productCreationInfo.variationGroupId3]?.some(v => v === vg.id)).map(vg => { return { id: vg.id, val: vg.val } });
            this.advancedInfoForm.get('variationsGroupArray').setValue(variationsGroups);

            //Associated Resources
            this.skipResourceHandler = true;

            if (this.productSupplyType != ProductSupplyType.buy) {
                this.resourcesSelect.setValue(
                    this.resourcesSelect.data.filter(x => this.productCreationInfo.Devices?.some(y => y.id === x.id))
                );
            }

            this.skipResourceHandler = false;
            this.productCreationInfo.Devices.forEach(device => {
                this.selectedResources.push({
                    id: device.id,
                    val: device.label,
                    collapsed: true,
                });
                this.resourcesFormArray.push(
                    this._fb.group({
                        multiplier: [device.ProductsDevice.multiplier, Validators.min(this.minMultiplierValue)],
                        scrapsMultiplier: [device.ProductsDevice.scrapsMultiplier, Validators.min(this.minMultiplierValue)],
                        inputMultiplier: [device.ProductsDevice.inputMultiplier, Validators.min(this.minMultiplierValue)],
                        targetSpeed: [device.ProductsDevice.targetSpeed, Validators.min(0)],
                        startThreshold: [device.ProductsDevice.startThreshold, Validators.min(1)],
                        stopThreshold: [device.ProductsDevice.stopThreshold ? device.ProductsDevice.stopThreshold / 1000 : undefined, Validators.min(0.001)],
                        divider: [device.ProductsDevice.divider, this.integerValidator],
                        defaultQuantity: [device.ProductsDevice.defaultQuantity, Validators.min(1)],
                    })
                );
            });
        }
    }

    setScollsHeight() {
        if (this.rightWrp && this.advancedInfoDivider) {
            this.advancedInfoScroolHeight = this.advancedInfoDivider.nativeElement.offsetTop - this.rightWrp.nativeElement.offsetTop;
        }
        if (this.rightWrp && this.resourcesDivider) {
            this.resourcesScroolHeight = this.resourcesDivider.nativeElement.offsetTop - this.rightWrp.nativeElement.offsetTop;
        }
    }

    goBack() {
        this._router.navigate(['/products']);
    }

    async companyChanged(company: CompanyInstance): Promise<void> {
        if (!company) {
            return;
        }
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;
        this.plants = this.selectedCompany.Plants.map(plant => {
            return {
                ...plant,
                val: plant.description
            }
        });
        const isSelectedPlantInCurrentCompany = this.selectedPlant?.id ?
            company.Plants.find((p) => p.id === this.selectedPlant.id) : false;
        await this._handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany ? true : false);
        await this.plantChanged(this.selectedPlant, false);
    }

    async _handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany: boolean) {
        // set plant
        if (!this.selectedPlant?.id || !isSelectedPlantInCurrentCompany) {
            this.selectedPlant = this.plants[0];
        } else if (this.plants.length > 0) {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlant.id);
        }
        this.selectedPlantId = this.selectedPlant.id;
    }

    async plantChanged(plant: PlantInstance, showSpinner: boolean) {
        if (showSpinner) this._spinner.show();
        try {
            this.selectedPlant = plant;
            this.selectedPlantId = this.selectedPlant.id;
            localStorage.setItem('plantId', this.selectedPlant.id.toString());

            this.resources = (await this._device.getDevicesByCompany(this.selectedCompanyId)).map(d => { return { id: d.id, val: d.label } });
            this.selectProductMesureUnitsData = (await this._productionUnits.getUnits(this.selectedCompanyId)).map(m => { return { id: m.id, val: m.unit } });;
            this.selectProductFamiliesData = (await this._families.getFamilies(this.selectedCompanyId)).map(pf => { return { id: pf.id, val: pf.code } });
        } catch (err) {
            console.warn(err);
        } finally {
            if (showSpinner) this._spinner.removeOverlay();
        }
    }

    private async loadData(showSpinner: boolean) {
        this.resources = [];
        if (showSpinner) this._spinner.show();
        try {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlantId) || this.plants[0];
            if (!this.selectedPlant) {
                throw new Error();
            }
            this.selectedPlantId = this.selectedPlant.id;
            this.variationsGroups = (await this._variations.getVariationsGroups(this.selectedCompanyId)).map(v => { return { id: v.id, val: v.code } });
            this.resources = (await this._device.getDevicesByCompany(this.selectedCompanyId)).map(d => { return { id: d.id, val: d.label } });
            this.selectProductMesureUnitsData = (await this._productionUnits.getUnits(this.selectedCompanyId)).map(m => { return { id: m.id, val: m.unit, dec: m.decimal } });;
            this.selectProductFamiliesData = (await this._families.getFamilies(this.selectedCompanyId)).map(pf => { return { id: pf.id, val: pf.code } });
        } catch (err) {
            this._ui.showNotification(err, 'alert');
        } finally {
            if (showSpinner) this._spinner.removeOverlay();
        }
    }

    toggleAdditionalInfo(event) {
        this.additionalInfoOpen = !this.additionalInfoOpen;
    }

    toggleAbsoluteValues(event) {
        this.absoluteValuesOpen = false;
    }

    get keyValueArray() {
        return this.additionalInfoForm.get('keyValueArray') as FormArray;
    }

    get resourcesFormArray() {
        return this.resourcesForm.get('resourcesFormArray') as FormArray;
    }

    handleRemoveInfoButtonClicked(event, i) {
        this.infoItems = [...this.infoItems.slice(0, i), ...this.infoItems.slice(i + 1)];
        this.keyValueArray.removeAt(i);
    }

    handleRemoveResourceClicked(event, i) {
        //Remove controller i in resourcesFormArray
        this.resourcesFormArray.removeAt(i);

        //Update selected resources
        this.selectedResources = [...this.selectedResources.slice(0, i), ...this.selectedResources.slice(i + 1)];

        //Update select ui (selected elements values)
        const values = [...(<[]>this.resourcesSelect.value)];
        this.skipResourceHandler = true;
        this.resourcesSelect.setValue([...values.slice(0, i), ...values.slice(i + 1)]);
        this.skipResourceHandler = false;
        const valuesa = [...(<[]>this.resourcesSelect.value)];
    }

    handleMenuClicked(menu: menus) {
        this.selectedMenu = <menus>menu;
        this.autoscrolling = true;
        switch (this.selectedMenu) {
            case menus.Registry:
                this.rightWrp.nativeElement.scrollIntoView({ behavior: 'smooth' });
                break;
            case menus.advancedInfo:
                this.advancedInfoDivider.nativeElement.scrollIntoView({ behavior: 'smooth' });
                break;
            case menus.AssociatedResources:
                this.resourcesDivider.nativeElement.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                this.autoscrolling = false;
                break;
        }
    }

    onRightScroll(event) {
        if (this.autoscrolling) return;
        const scrollTop = this.rightWrp.nativeElement.scrollTop;
        if (scrollTop < this.rightWrp.nativeElement.offsetHeight / 2) {
            this.selectedMenu = menus.Registry;
        } else if (scrollTop < ((this.rightWrp.nativeElement.offsetHeight / 2) + 20)) {
            this.selectedMenu = menus.advancedInfo;
        } else {
            this.selectedMenu = menus.AssociatedResources;
        }
    }

    handleNewInfoButtonClicked() {
        this.infoItems = [...this.infoItems, { description: '', value: '' }];
        this.keyValueArray.push(this._fb.group({
            key: ['', Validators.required],
            value: ['', Validators.required]
        }));
    }
    handleResourcesChanged(event: { id: number, val: string }[]) {
        if (this.skipResourceHandler) return;
        const addedResources = event.length - this.selectedResources.length;

        if (addedResources == 1) {
            this.selectedResources.push({
                id: event[event.length - 1].id,
                val: event[event.length - 1].val,
                collapsed: false,
            });
            this.resourcesFormArray.push(
                this._fb.group({
                    multiplier: ['', [Validators.min(1), Validators.pattern(/^\d+$/)]],
                    scrapsMultiplier: ['', Validators.min(1)],
                    inputMultiplier: ['', Validators.min(1)],
                    targetSpeed: ['', Validators.min(0)],
                    startThreshold: ['', Validators.min(1)],
                    stopThreshold: ['', Validators.min(0.001)],
                    divider: ['', Validators.min(1)],
                    defaultQuantity: ['', Validators.min(0)],
                })
            );
        } else if (addedResources == -1) {
            const i = this.selectedResources.findIndex(r => event.findIndex(e => { r.id == e.id }) == -1);
            this.selectedResources = [...this.selectedResources.slice(0, i), ...this.selectedResources.slice(i + 1)];
            this.resourcesFormArray.removeAt(i);
        }

        this.setScollsHeight();

        if (this.registryForm.get('measure')?.value) {
            this.onUnitChange(this.registryForm.get('measure')?.value)
        }
    }

    handleVariationsGroupChanged(event: { id: number, val: string }[]) {
        if (!this.variationsSelect) return;
        const addedVariations = event.length - this.selectedVariationsGroup.length;
        if (addedVariations == 1) {
            this.selectedVariationsGroup.push({
                id: event[event.length - 1].id,
                val: event[event.length - 1].val,
            });
        } else if (addedVariations == -1) {
            const i = this.selectedVariationsGroup.findIndex(r => event.findIndex(e => { r.id == e.id }) == -1);
            this.selectedVariationsGroup = [...this.selectedVariationsGroup.slice(0, i), ...this.selectedVariationsGroup.slice(i + 1)];
        }
        if (this.selectedVariationsGroup.length == 3) {
            this.variationsSelect.enabled = false;
            this.variationsSelect.closeDropDown();
        } else {
            this.variationsSelect.enabled = true;
        }
    }

    collapseResourceRow(event, resId) {
        this.selectedResources[this.selectedResources.findIndex(r => r.id == resId)].collapsed = !this.selectedResources[this.selectedResources.findIndex(r => r.id == resId)].collapsed;
    }


    handleCancelClicked() {
        if (this.registryForm.get('code').value) {
            let mode: GenericDialogComponentModes;
            if (this.registryForm.get('description').value && this.registryForm.get('measure').value && (this.registryForm.get('type')?.value || this.productSupplyType == ProductSupplyType.buy)) {
                mode = GenericDialogComponentModes.exitSave;
            } else {
                mode = GenericDialogComponentModes.cancel;
            }

            const title = this._translate.instant('products.modal.exitCreationlTitle');
            const message = this._translate.instant('products.modal.saveAbortMessage', { code: this.registryForm.get('code').value })
            const { popupConfig, popupBodyConfig } = this.showPopup(title, message, mode);

            this._popup.show(popupConfig, popupBodyConfig).subscribe(async (result) => {
                console.log(result);
                if (result === 'primary') {
                    this.saveProductAndExit();
                } else if (result === 'secondary') {
                    this.goBack();
                } else {
                    return;
                }
            })
        } else {
            this.goBack();
        }
    }

    handleCodeChanged() {
        this.isDuplicateHasErrors = { code: false, description: this.isDuplicateHasErrors.description };
    }

    handleDescriptionChanged() {
        this.isDuplicateHasErrors = { code: this.isDuplicateHasErrors.code, description: false };
    }

    formValid() {
        return this.registryForm.valid;
    }

    handleSaveClicked() {
        if (!this.registryForm.valid) {
            this.registryForm.markAllAsTouched();
            return;
        }

        if (!this.additionalInfoForm.valid) {
            this.additionalInfoOpen = true;
            this.additionalInfoForm.markAllAsTouched();
            return;
        }
        if (!this.resourcesFormArray.valid) {
            this.resourcesFormArray.markAllAsTouched();
            return;
        }
        if (!this.advancedInfoForm.valid) {
            this.advancedInfoForm.markAllAsTouched();
            return;
        }

        this.modalData = {
            title: this._translate.instant('products.modal.saveTitle'),
            message: this._translate.instant('products.modal.saveMessage', { code: this.registryForm.get('code').value }),
            mode: GenericDialogComponentModes.save,
        };

        const { popupBodyConfig, popupConfig } = this.showPopup(this.modalData.title, this.modalData.message, this.modalData.mode);
        this._popup.show(popupConfig, popupBodyConfig).subscribe(async (res) => {
            if (res === 'primary') {
                this.saveProduct();
            } else {
                return;
            }
        })
    }

    async saveProductAndExit() {
        await this.saveProduct();
    }

    onUnitChange(p) {
        const selectedUnit = <{ id: number, val: string, dec: number }>this.selectProductMesureUnitsData.find(u => u.id == p?.id)
        if (selectedUnit?.dec || selectedUnit?.dec === 0) {
            this.minMultiplierValue = 1 / (10 ** selectedUnit?.dec)
        } else {
            this.minMultiplierValue = 0.1;
        }

        const multiplierControl = <any>(this.resourcesFormArray?.controls);

        if (multiplierControl.length) {
            multiplierControl[0]?.get('multiplier')?.setValidators([Validators.min(this.minMultiplierValue)]);
            multiplierControl[0]?.get('multiplier')?.updateValueAndValidity();
            multiplierControl[0]?.get('scrapsMultiplier')?.setValidators([Validators.min(this.minMultiplierValue)]);
            multiplierControl[0]?.get('scrapsMultiplier')?.updateValueAndValidity();
            multiplierControl[0]?.get('inputMultiplier')?.setValidators([Validators.min(this.minMultiplierValue)]);
            multiplierControl[0]?.get('inputMultiplier')?.updateValueAndValidity();
        }
    }

    async saveProduct() {
        const resources = this.resourcesFormArray.controls.map((r, i) => { return { deviceId: this.selectedResources[i].id, ...(r.getRawValue()) } });
        for (let i = 0; i < resources?.length; i++) {
            const resource = resources[i];
            Object.keys(resource)?.map(key => {
                resource[key] = resource[key] || null
                if (key === "stopThreshold" && !!resource[key]) {
                    resource[key] = resource[key] * 1000;
                }
            });
        }
        const jsonInfo = {};
        const jsonInfoRaw = this.keyValueArray.controls.map(c => c.getRawValue());
        jsonInfoRaw.forEach(j => jsonInfo[j.key] = j.value);

        let product: Product = {
            code: this._sanitizer.sanitize(SecurityContext.HTML, this.registryForm.get('code').value),
            companyId: this.selectedCompanyId,
            familyId: this.registryForm.get('family')?.value?.id,
            type: this.registryForm.get('type')?.value?.id,
            unitId: this.registryForm.get('measure').value.id,
            description: this._sanitizer.sanitize(SecurityContext.HTML, this.registryForm.get('description').value),
            batchEnabled: this.advancedInfoForm.value.batchEnabled,
            serialNumberEnabled: this.advancedInfoForm.value.serialNumberEnabled,
            jsonInfo: jsonInfo || null,
            Devices: resources || null,
        };

        if (this.productSupplyType == ProductSupplyType.make) {
            product.type = this.registryForm.get('type').value.id == ProductType.product ? ProductType.product : ProductType.semiFinished;
        } else {
            product.type = ProductType.rawMaterial;
        }

        if (this.isDuplicate) {
            let hasError = false;
            this.isDuplicateHasErrors = { code: false, description: false }
            if (this.codeValueOriginal == product.code) {
                this.isDuplicateHasErrors.code = true;
                hasError = true;
            }
            if (this.descriptionValueOriginal == product.description) {
                this.isDuplicateHasErrors.description = true;
                hasError = true;
            }
            if (hasError) return;
        }

        if (!this.isEditing || !this.isDuplicate) {
            let variationsGroupArray = this.advancedInfoForm.getRawValue().variationsGroupArray;
            if (variationsGroupArray.length) {
                product.variationGroupId1 = variationsGroupArray[0]?.id;
                product.variationGroupId2 = variationsGroupArray[1]?.id;
                product.variationGroupId3 = variationsGroupArray[2]?.id;
            }
        }
        try {
            let result;
            if (this.isEditing) {
                const productInstanceForUpdate: Product = {
                    id: this.productCreationInfo?.id,
                    code: product.code,
                    description: product.description,
                    familyId: product.familyId,
                    unitId: product.unitId,
                    type: product.type,
                    jsonInfo: product.jsonInfo,
                    Devices: product.Devices,
                    variationGroupId1: product.variationGroupId1,
                    variationGroupId2: product.variationGroupId2,
                    variationGroupId3: product.variationGroupId3,
                    batchEnabled: product.batchEnabled,
                    serialNumberEnabled: product.serialNumberEnabled,
                }
                this._spinner.show();
                result = await this._product.update(productInstanceForUpdate);
            } else {
                this._spinner.show();
                result = await this._product.create(product);
            }
            if (result.statusText == 'OK') {
                const { title, style, message } = this.createToast({ code: product.code, created: true, aborted: false });
                this._ui.showNotification(message, style, title);
            } else {
                const { title, style, message } = this.createToast({ code: product.code, created: false, aborted: false });
                this._ui.showNotification(message, style, title);
            }
            this._router.navigate(['/products']);
            this._spinner.removeOverlay();
        } catch (error) {
            this._spinner.removeOverlay();
            if (error?.error == "Conflict") {
                this._ui.showNotification(this._translate.instant('products.newEditProduct.codeAlreadyUsed', { code: product.code }), 'alert');
            } else {
                this._ui.showNotification(this._translate.instant('products.newEditProduct.createError', { code: product.code }), 'alert');
            }
        }
    }

    handleSerialNumberEnabledChange(event: boolean) {
        this.advancedInfoForm.patchValue({
            serialNumberEnabled: event
        });
    }

    handleBatchEnabled(event: boolean) {
        this.advancedInfoForm.patchValue({
            batchEnabled: event
        })
    }

    private createToast(productCreated: { code: string, created: boolean, aborted: boolean }): { title: string, message: string, style: NotificationStyle } {
        let title: string;
        let message: string;
        let style: NotificationStyle = (productCreated?.created || productCreated.aborted) ? 'check' : 'error';
        if (productCreated?.created === true) {
            title = this._translate.instant('products.productCreatedTitle');
            message = this._translate.instant('products.productCreatedMessage', { code: productCreated.code });
        } else if (productCreated?.aborted === true) {
            title = this._translate.instant('products.productCreatedAbortTitle');
            message = this._translate.instant('products.productCreatedAbortMessage');
        } else if (productCreated?.created === false) {
            title = this._translate.instant('products.productCreatedAbortTitle');
            message = this._translate.instant('products.productCreatedAbortMessage');
        }

        return { title, message, style };
    }

    private showPopup(title: string, message: string, mode: GenericDialogComponentModes): { popupConfig: PopupConfig, popupBodyConfig: PopupBodyConfig } {
        const popupConfig: PopupConfig = {
            title: title,
            level: 'info',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
        };

        const popupBodyConfig: PopupBodyConfig = {
            content: message
        };

        if (mode === GenericDialogComponentModes.exitSave) {
            popupConfig['bottomButtons'] = {
                primary: {
                    text: this._translate.instant('dialogs.saveAndExit'),
                },
                secondary: {
                    text: this._translate.instant('dialogs.noSaveAndExit'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.cancel'),
                },
            }
        }

        if (mode === GenericDialogComponentModes.save) {
            popupConfig['bottomButtons'] = {
                primary: {
                    text: this._translate.instant('dialogs.save'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.cancel'),
                },
            }
        }

        if (mode === GenericDialogComponentModes.cancel) {
            popupConfig['bottomButtons'] = {
                primary: {
                    text: this._translate.instant('dialogs.confirm'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.cancel'),
                },
            }
        }

        if (mode === GenericDialogComponentModes.delete) {
            popupConfig['bottomButtons'] = {
                primary: {
                    text: this._translate.instant('dialogs.del'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.cancel'),
                },
            }
        }

        return { popupConfig, popupBodyConfig };
    }
}
