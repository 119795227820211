import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyInstance } from '../../../models/company.model';

@Component({
  selector: 'app-company-switcher',
  templateUrl: './company-switcher.component.html',
  styleUrls: ['./company-switcher.component.scss']
})
export class CompanySwitcherComponent implements OnInit {

  @Input() companies: CompanyInstance[] = [];
  @Input() selectedCompany: CompanyInstance;

  @Output() onCompanyChange = new EventEmitter<CompanyInstance>();

  filteredCompanies: CompanyInstance[] = [];
  filterResult: string = '';

  showCompanies: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.filteredCompanies = [...this.companies];
  }

  showCompanyList() {
    this.showCompanies = !this.showCompanies;
    if (!this.showCompanies) {
      this.resetCompanies();
    }
  }

  onSelectCompany(company: CompanyInstance) {
    this.onCompanyChange.emit(company);
    this.showCompanies = false;
    this.resetCompanies();
  }

  handleValueChangeCompanyFilter(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredCompanies = this.companies.filter(company => company.name.toLowerCase().includes(filterValue));
  }

  resetCompanies() {
    this.filteredCompanies = [...this.companies];
    this.filterResult = '';
  }

  onClose(isOpen: boolean) {
    if (!isOpen) {
      this.showCompanies = false;
      this.resetCompanies();
    }
  }
}
