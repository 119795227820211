import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FontService, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';
import { AuthService } from '../../../services/auth.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { Subscription } from 'rxjs';
import { UserRole } from '../../../models/user.model';
import { CompanyInstance } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { ProductFamilyService } from '../../../services/productFamily.service';
import { ProductsFamilyInstance } from '../../..//models/productsFamily.model';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Router } from '@angular/router';


@Component({
    selector: 'families',
    templateUrl: './families.component.html',
    styleUrls: ['./families.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class FamiliesComponent implements OnInit {

    isAdmin: boolean;
    isManager: boolean;
    selectedCompany: CompanyInstance;
    selectedCompanyId: number;
    _subscriptions: Subscription[] = [];
    familiesColumns: TsTreeListColumn[] = [];
    familiesListHeight = 'calc(100vh - 220px)';
    families: ProductsFamilyInstance[];
    selectedFamily: ProductsFamilyInstance = null;

    @ViewChild('familiesList', { static: false, read: ElementRef }) familiesListRef!: ElementRef;

    constructor(
        private _ui: UiService,
        private _font: FontService,
        private _auth: AuthService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _spinner: OverlaySpinnerService,
        private _router: Router,
        private _translate: TranslateService,
        private _company: CompanyService,
        private _family: ProductFamilyService,
        private _popup: PopupService
    ) {
        this._font.addIcon(faPencil, faTrashAlt);
    }

    async ngOnInit() {
        this._spinner.show();
        try {
            const translationSubscription = this._translate.stream([
                'products.title',
                'products.search',
                'productsFamily.columns.code',
                'productsFamily.columns.description',
            ]).subscribe((translations) => {
                this._navbar.setTitle(translations['products.families.title']);
                this._sidebar.setSelected('families');

                const defaultColumn: TsTreeListColumn = {
                    dataField: '',
                    headerPlaceholder: translations['products.search'],
                    allowFiltering: true,
                    allowEditing: false,
                    allowSorting: true
                };
                this.familiesColumns =
                    [
                        { // id
                            caption: 'id',
                            dataField: 'id',
                            showColumn: false,
                        },
                        { // code
                            ...defaultColumn,
                            caption: translations['productsFamily.columns.code'],
                            dataField: 'code',
                            dataType: 'string',
                            width: 302,
                        },
                        { // description
                            ...defaultColumn,
                            caption: translations['productsFamily.columns.description'],
                            dataField: 'description',
                            dataType: 'string',
                        },
                        { // actions
                            ...defaultColumn,
                            caption: '',
                            fixedPosition: 'right',
                            alignment: 'center',
                            width: 110,
                            allowFiltering: false,
                            allowResizing: false,
                            allowFixing: false,
                            allowSorting: false,
                            cellTemplate: 'tplActions',
                        },
                    ];
            });
            this._subscriptions.push(translationSubscription);

            this.isAdmin = this._auth.user.role === UserRole.admin;
            this.isManager = this._auth.user.role === UserRole.manager;
            this.selectedCompanyId = Number(localStorage.getItem('companyId'));
            if (this.isAdmin || this.isManager) {
                await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
            } else {
                this.selectedCompany = await this._company.getCompany(this.selectedCompanyId);
            }
            const companyChangeSubscription =
                this._company.changeCompanyEmitted$.subscribe(company => {
                    if (this.isAdmin) {
                        this.companyChanged(company, true);
                    }
                });
            this._subscriptions.push(companyChangeSubscription);
            this._spinner.removeOverlay();
        } catch (err) {
            this._ui.showNotification(err, 'error');
        } finally {
            this._spinner.removeOverlay();
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    async companyChanged(company: CompanyInstance, showSpinner: boolean = false) {
        if (!company) {
            return;
        }
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;

        if (showSpinner) this._spinner.show();
        try {
            this.families = await this._family.getFamilies(this.selectedCompanyId);
        } catch (err) {
            console.warn(err);
        } finally {
            if (showSpinner) this._spinner.removeOverlay();
        }
    }

    onNewfamilyClick() {
        this._router.navigate(['families/new']);
    }

    onDeleteFamilyClicked(family: ProductsFamilyInstance) {
        this.selectedFamily = family;

        const popupConfig: PopupConfig = {
            title: this._translate.instant('productsFamily.delete.title'),
            level: 'error',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: {
                primary: {
                    text: this._translate.instant('dialogs.del'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.cancel'),
                },
            },
        };
        const bodyConfig: PopupBodyConfig = {
            content: this._translate.instant('productsFamily.delete.text', { name: family.code }),
        };

        this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {
            try {
                if (result === 'primary') {
                    const res = await this._family.deleteFamily(this.selectedFamily.id);

                    if (res) {
                        this.families = this.families.filter(f => f.id !== this.selectedFamily.id);
                        this._ui.showNotification(this._translate.instant('productsFamily.delete.successText', { name: this.selectedFamily.code }));
                    } else {
                        this._ui.showNotification(this._translate.instant('productsFamily.deletedMessageErrorText'), 'error');
                    }
                } else {
                    console.log('Canceled');
                    return
                }
            } catch (error) {
                console.log(error);
                this._ui.showNotification(this._translate.instant('companies.error'), 'error');
            }
        })
    }

    onEditFamilyClicked(family: ProductsFamilyInstance) {
        this._router.navigate([`families/edit/${family.id}`]);
    }
}
