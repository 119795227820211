<div class="process-data-page">
    <div class="process-data-page-content">
        <div class="header">
            <div class="column">
                <h2>{{'processData.title' | translate}}</h2>
            </div>
            <div class="column">
                <div class="right">
                    <ng-container *ngIf="tmpDevices?.length > 0">
                        <ts-select [data]="tmpDevices" [value]="selectedDevice" labelKey="label"
                                   (valueChange)="deviceChanged($event)" [enableSearch]="true"
                                   [clearable]="false"></ts-select>
                    </ng-container>
                    <ng-container *ngIf="plants">
                        <ts-select [data]="plants" [value]="selectedPlant" labelKey="description"
                                   (valueChange)="plantChanged($event)" [enableSearch]="true"
                                   [clearable]="false"></ts-select>
                    </ng-container>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!isDisabled">
            <div class="ts-vapor ts-layout layout-container">

                <div class="new-process-row">
                    <div class="column">
                        <div class="button-group-end">
                            <ts-button text="{{'users.addNew' | translate}}" type="primary medium blue"
                                       (buttonClicked)="createOrUpdateProcess()"></ts-button>
                        </div>
                    </div>
                </div>

                <ts-tree-list #processList [data]="processData" [columns]="columns" parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" [enablePaging]="false"
                              [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                              [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                              [allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="processListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data>
                        <span *ngIf="tplKey === 'tplActions'">

                            <ts-tree-list-button icon="pencil" title="Edit"
                                                 (click)="createOrUpdateProcess(data)"></ts-tree-list-button>
                            <ts-tree-list-button icon="trash-alt" title="Delete" [isDanger]="true"
                                                 (click)="deleteProcessData(data)"></ts-tree-list-button>
                        </span>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>

        <div *ngIf="isDisabled" class="pd-not-available" style="margin-top: 10px;">
            {{'processData.not-available' | translate}}</div>
    </div>

</div>

<ng-template #newProcessTemplate>
    <app-process-data-drawer #processDrawer [valueTypes]="valueTypes"
                             [assignedValueTypes]="assignedValueTypes"></app-process-data-drawer>
</ng-template>

<ng-template #editProcessTemplate>
    <app-process-data-drawer #processDrawer [valueTypes]="valueTypes" [assignedValueTypes]="assignedValueTypes"
                             [processData]="selectedProcess"></app-process-data-drawer>
</ng-template>

<ng-template #processDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="onFormSubmit()"
                   [disableExit]="!processDrawer?.formValid">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>