<div class="login-page">
    <div class="login-form">
        <img src="../../../../assets/images/team_system/login-logo.png" width="300" />

        <form [formGroup]="loginForm" *ngIf="!passwordLost">
            <ts-text-box primaryText="{{'login.email' | translate}} *" placeholderText="{{'login.email' | translate}}"
                         [validationDict]="customValidationDict | translateJSON | async" formControlName="mail"
                         [showErrors]="true" [prefixWidth]="'100%'" [suffixWidth]="'100%'"></ts-text-box>

            <ts-text-box primaryText="{{'login.password' | translate}} *" mode="password"
                         placeholderText="{{'login.password' | translate}}"
                         [validationDict]="customValidationDict | translateJSON | async" formControlName="password"
                         [showErrors]="true"></ts-text-box>
            <div class="form-footer">
                <ts-button type="primary blue" text="{{'login.login' | translate}}" (buttonClicked)="loginSubmit()"
                           [disabled]="loading"></ts-button>
                <span (click)="isPasswordLost(true)" class="forgot-password">{{'login.passwordLost' | translate}}</span>
            </div>
        </form>

        <form [formGroup]="resetForm" *ngIf="passwordLost">
            <span>{{'login.recover.text' | translate}}</span>
            <ts-text-box placeholderText="{{'login.email' | translate}}"
                         [validationDict]="customValidationDict | translateJSON | async" formControlName="recover_mail"
                         [showErrors]="true" [prefixWidth]="'100%'" [suffixWidth]="'100%'"></ts-text-box>

            <div class="form-footer">
                <ts-button type="primary blue medium" text="{{'login.recover.send' | translate}}"
                           (buttonClicked)="resetSubmit()" [disabled]="loading"></ts-button>
                <ts-button type="tertiary blue medium" text="{{'login.recover.close' | translate}}"
                           (buttonClicked)="isPasswordLost(false)" [disabled]="loading"></ts-button>
            </div>
        </form>
    </div>
</div>