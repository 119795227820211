import { Component, Input, OnInit } from '@angular/core';
import { ProcessDataInstance} from '../../../models/process-data.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-process-data-drawer',
  templateUrl: './process-data-drawer.component.html',
  styleUrls: ['./process-data-drawer.component.scss']
})
export class ProcessDataDrawerComponent implements OnInit {

  @Input() processData?: ProcessDataInstance;
  @Input() valueTypes: {
    id: number;
    name: string;
    value: number
  }[];

  @Input() assignedValueTypes: number[];

  selectedType: {
    id: number;
    name: string;
    value: any;
  };

  form: FormGroup;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  constructor(
    private readonly _fb: FormBuilder,
    private _translate: TranslateService
  )
     {
    this.form = this._fb.group({
      code: [null, [Validators.required]],
      description: [null, [Validators.required]],
      valueType: [null]
    })
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {

    this.updateValueTypes();

    if (this.processData) {
      this.form.reset();
      this.updateForm(this.processData)
    }
  }

  onSubmit() {
    if (!this.formValid) {
      return
    }

    const data = {
      ...this.form.value,
      valueType: this.form.value.valueType?.id ?? 0
    }

    if (this.processData) {
      data['id'] = this.processData.id
    }

    return data
  }

  private updateForm(processData: ProcessDataInstance) {
    this.form.patchValue({
      code: processData.code,
      description: processData.description,
      valueType: processData.valueType,
    });

    this.selectedType = this.valueTypes.find((type) => type.id === processData.valueType);
  }

  private updateValueTypes() {
    this.valueTypes = this.valueTypes.map((type) => {
      const isAssigned = this.assignedValueTypes.includes(type.value);
      return {
        id: type.id,
        name: isAssigned ? `${type.name} (${this._translate.instant('devices.already_assigned')}) ` : type.name,
        value: type.value,
        disabled: isAssigned
      };
    });
  }
}
