<div class="quality-controls-page">

    <div class="quality-controls-list-content">
        <div class="header">
            <div class="column">
                <h2>{{'quality-controls.title' | translate}}</h2>
            </div>
            <div class="column">
                <div class="right">
                    <ng-container *ngIf="plants && plants.length > 1">
                        <ts-select [data]="plants" [value]="selectedPlant.description" [clearable]="false"
                                   [enableSearch]="true" (valueChange)="plantChanged($event)"
                                   labelKey="description"></ts-select>
                    </ng-container>
                </div>
            </div>
        </div>

        <ng-container>
            <div class="ts-vapor ts-layout layout-container">
                <div class="new-control-row">
                    <div class="column">
                        <div class="button-group-end">
                            <ts-button text="{{'users.addNew' | translate}}" type="primary medium blue"
                                       (buttonClicked)="addControl()"></ts-button>
                        </div>
                    </div>
                </div>

                <ts-tree-list #controlsList [data]="controls" [columns]="columns" key="id" parentKey="groupId"
                              actionsColumnCaption="" groupedActionsTooltipText=""
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              emptyStateSecondaryText="" emptyStateSecondaryLink="" [enablePaging]="false"
                              [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                              [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                              [allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="controlsListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <app-quality-controls-list-cell [tplKey]="tplKey" [data]="data"
                                                        (controlInfo)="showControlInfo(data)" (editControl)="edit(data)"
                                                        (deleteControl)="deleteControl(data.id)"
                                                        (duplicateControl)="duplicateControl(data.id)"
                                                        (enableControl)="enablingControl(data.id)"></app-quality-controls-list-cell>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>
    </div>

    <ng-template #controlInfoTemplate>
        <app-quality-control-drawer [control]="selectedControl"></app-quality-control-drawer>
    </ng-template>

    <router-outlet></router-outlet>
</div>