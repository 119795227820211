<div class="tags-page">

    <div class="tags-page-content">
        <div class="header">
            <div class="column">
                <h2>{{'tags.title' | translate}}</h2>
            </div>
            <!-- <div class="column" *ngIf="companies?.length > 0 && isAdmin">
                <div class="right">
                    <ng-container>
                        <ts-select [data]="companies" [value]="selectedCompany" labelKey="name"
                                   (valueChange)="companyChanged($event)" [enableSearch]="true"
                                   [clearable]="false"></ts-select>
                    </ng-container>
                </div>
            </div> -->
        </div>

        <ng-container>
            <div class="ts-vapor ts-layout layout-container">

                <div class="new-tag-row">
                    <div class="column">
                        <div class="button-group-end">
                            <ts-button text="{{'tags.addNew' | translate}}" type="primary medium blue"
                                       (buttonClicked)="createOrUpdateTag()"></ts-button>
                        </div>
                    </div>
                </div>

                <ts-tree-list #tagList [data]="tmpTags" [columns]="columns" parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" [enablePaging]="false"
                              [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                              [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                              [allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="tagListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data>
                        <span *ngIf="tplKey === 'tplActions'">

                            <ts-tree-list-button icon="pencil" title="Edit"
                                                 (click)="createOrUpdateTag(data)"></ts-tree-list-button>
                            <ts-tree-list-button icon="trash-alt" [isDanger]="true" title="Delete"
                                                 (click)="deleteTag(data)"></ts-tree-list-button>
                        </span>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>
    </div>

</div>

<ng-template #newTagTemplate>
    <app-tags-drawer #tagsDrawer></app-tags-drawer>
</ng-template>

<ng-template #editTagTemplate>
    <app-tags-drawer #tagsDrawer [tag]="selectedTag"></app-tags-drawer>
</ng-template>

<ng-template #tagDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="onFormSubmit()"
                   [disableExit]="!tagsDrawer?.formValid">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>