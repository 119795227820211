<ts-simple-checkbox *ngIf="tplKey === 'tplPriority'" [value]="data.id === priorityErrorId"
                    (valueChange)="onPriorityChange(data.id, $event)"></ts-simple-checkbox>

<ng-container *ngIf="tplKey === 'tplDevices'">
    <ts-tree-list-cell *ngIf="data?.Devices?.length == 1" [cellText]="data?.Devices[0]?.label" [hyperlinkEnabled]="false"
                       [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.Devices?.length > 1"
                       cellText="{{ 'orders-list.cells.devices' | translate:{ first: data?.Devices[0]?.label, others: data?.Devices?.length - 1 } }}"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
</ng-container>

<ng-container *ngIf="tplKey === 'tplTags'">
    <ts-tree-list-cell [cellText]="data?.ErrorsTags[0]?.name" [hyperlinkEnabled]="false"
                       [buttonIsVisible]="false"></ts-tree-list-cell>
</ng-container>
<span *ngIf="tplKey === 'tplActions'">
    <ts-tree-list-button icon="pencil" title="Edit" (click)="editError(data.id)"></ts-tree-list-button>
    <ts-tree-list-button icon="trash-alt" [isDanger]="true" title="Delete"
                         (click)="deleteError(data)"></ts-tree-list-button>
</span>