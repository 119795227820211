<div class="errors-page">

    <div class="error-list-content">
        <div class="header">
            <div class="column">
                <h2>{{'stopReasons.title' | translate}}</h2>
            </div>
            <div class="column">
                <div class="right">
                    <ng-container *ngIf="devices?.length > 1">
                        <ts-select [data]="devices" [value]="selectedDevice.customLabel" [clearable]="false"
                                   [enableSearch]="true" (valueChange)="deviceChanged($event)"
                                   labelKey="customLabel"></ts-select>
                    </ng-container>
                </div>
            </div>
        </div>

        <ts-tab-panel [panelTabs]="tabs" type="primary" barType="standard" size="small" [(selectedTab)]="selectedTabId"
                      [selectTabOnClick]="true" (selectedTabChange)="handleTabChange($event)">
        </ts-tab-panel>

        <ng-container [ngSwitch]="selectedTabId">
            <div class="ts-vapor ts-layout layout-container">
                <div class="new-error-row">
                    <div class="column">
                        <div class="button-group-end">
                            <ts-button text="{{'users.addNew' | translate}}" type="primary medium blue"
                                       (buttonClicked)="showCreateOrUpdateDrawer(selectedTabId)"></ts-button>
                        </div>
                    </div>
                </div>
                <!-- Planned -->
                <ts-tree-list *ngSwitchCase="1" #errorsList [data]="tmpPlanned" [columns]="columns" parentKey="groupId"
                              key="id" emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" emptyStateSecondaryText=""
                              emptyStateSecondaryLink="" [enablePaging]="false" [showSearchBar]="false"
                              [hideActionsColumn]="true" [showRowLines]="true" [showBorders]="false"
                              [allowRowDeleting]="false" [allowRowInserting]="false" [allowRowUpdating]="false"
                              [wordWrapEnabled]="true" [height]="errorsListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <app-errors-list-cell [tplKey]="tplKey" [data]="data"
                                              [priorityErrorId]="selectedDevice?.priorityErrorId"
                                              (priorityClicked)="updatePriorityError($event)"
                                              (editErrorClicked)="showCreateOrUpdateDrawer(selectedTabId, data.id)"
                                              (deleteErrorClicked)="deleteError(data)"></app-errors-list-cell>
                    </ng-template>
                </ts-tree-list>

                <!-- Unplanned -->
                <ts-tree-list *ngSwitchCase="0" #errorsList [data]="tmpUnplanned" [columns]="unplannedColumns"
                              parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" emptyStateSecondaryText=""
                              emptyStateSecondaryLink="" [enablePaging]="false" [showSearchBar]="false"
                              [hideActionsColumn]="true" [showRowLines]="true" [showBorders]="false"
                              [allowRowDeleting]="false" [allowRowInserting]="false" [allowRowUpdating]="false"
                              [wordWrapEnabled]="true" [height]="errorsListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <app-errors-list-cell [tplKey]="tplKey" [data]="data"
                                              [priorityErrorId]="selectedDevice?.priorityErrorId"
                                              (priorityClicked)="updatePriorityError($event)"
                                              (editErrorClicked)="showCreateOrUpdateDrawer(selectedTabId, data.id)"
                                              (deleteErrorClicked)="deleteError(data)"></app-errors-list-cell>
                    </ng-template>
                </ts-tree-list>

                <!-- Delays -->
                <ts-tree-list *ngSwitchCase="2" #errorsList [data]="tmpDelays" [columns]="delayColumns"
                              parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" emptyStateSecondaryText=""
                              emptyStateSecondaryLink="" [enablePaging]="false" [showSearchBar]="false"
                              [hideActionsColumn]="true" [showRowLines]="true" [showBorders]="false"
                              [allowRowDeleting]="false" [allowRowInserting]="false" [allowRowUpdating]="false"
                              [wordWrapEnabled]="true" [height]="errorsListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <app-errors-list-cell [tplKey]="tplKey" [data]="data"
                                              [priorityErrorId]="selectedDevice?.priorityErrorId"
                                              (priorityClicked)="updatePriorityError($event)"
                                              (editErrorClicked)="showCreateOrUpdateDrawer(selectedTabId, data.id)"
                                              (deleteErrorClicked)="deleteDelay(data)"></app-errors-list-cell>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>

    </div>
</div>

<ng-template #newErrorsTemplate [appClickOutside]>
    <app-errors-drawer #errorsDrawer [type]="selectedTabId" [devices]="devices" [errorCategories]="errorCategories"
                       [errorTags]="errorTags" [isEditing]="false"
                       [selectedDevice]="selectedDevice"></app-errors-drawer>
</ng-template>

<ng-template #editErrorsTemplate>
    <app-errors-drawer #errorsDrawer [type]="selectedTabId" [devices]="devices" [error]="selectedError"
                       [errorCategories]="errorCategories" [errorTags]="errorTags" [isEditing]="true"
                       [delay]="selectedDelay" [selectedDevice]="selectedDevice"></app-errors-drawer>
</ng-template>

<ng-template #errorsDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="onFormSubmit()"
                   [disableExit]="!errorsDrawer?.formValid">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>