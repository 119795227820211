<form [formGroup]="form" [autocomplete]="false" class="container device-drawer">
    <div class="cd-row" *ngIf="!isDevice">
        <ts-text-box primaryText="Nome" placeholderText="Nome" formControlName="description" [showErrors]="true"
                     width="300px" [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
    </div>
    <div class="cd-row">
        <span class="ts-input-container__header__primary__text">
            {{ 'companies.deviceModule.performances' | translate }}
        </span>
        <div class="ts-input-container__data">
            <ts-simple-checkbox formControlName="performances"></ts-simple-checkbox>
        </div>
    </div>
    <div class="cd-row">
        <span class="ts-input-container__header__primary__text">
            {{ 'companies.deviceModule.orders' | translate }}
        </span>
        <div class="ts-input-container__data">
            <ts-simple-checkbox formControlName="orders"></ts-simple-checkbox>
        </div>
    </div>
    <div class="cd-column" formGroupName="OrderModuleConfig" *ngIf="form.get('orders').value">
        <!-- Orders -->
        <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>

        <div class="cd-item">
            <span class="ts-input-container__header__primary__text">
                {{ 'companies.orderConfigModule.autoProgress' | translate }}
            </span>
            <div class="ts-input-container__data">
                <ts-simple-checkbox formControlName="autoProgress"></ts-simple-checkbox>
            </div>
        </div>

        <div class="cd-item">
            <span class="ts-input-container__header__primary__text">
                {{ 'companies.orderConfigModule.validateProgress' | translate }}
            </span>
            <div class="ts-input-container__data">
                <ts-simple-checkbox formControlName="validateProgress"></ts-simple-checkbox>
            </div>
        </div>

        <div class="cd-item">
            <span class="ts-input-container__header__primary__text">
                {{ 'companies.orderConfigModule.validateProgressQuality' | translate }}
            </span>
            <div class="ts-input-container__data">
                <ts-simple-checkbox formControlName="validateProgressQuality"></ts-simple-checkbox>
            </div>
        </div>

        <div class="cd-item">
            <span class="ts-input-container__header__primary__text">
                {{ 'companies.orderConfigModule.deltaTarget' | translate }}
            </span>
            <div class="ts-input-container__data">
                <ts-simple-checkbox formControlName="deltaTarget"></ts-simple-checkbox>
            </div>
        </div>

        <div class="cd-item">
            <span class="ts-input-container__header__primary__text">
                {{ 'companies.orderConfigModule.targetScrapsDiff' | translate }}
            </span>
            <div class="ts-input-container__data">
                <ts-simple-checkbox formControlName="targetScrapsDiff"></ts-simple-checkbox>
            </div>
        </div>

        <div class="cd-item">
            <span class="ts-input-container__header__primary__text">
                {{ 'companies.orderConfigModule.multiOrder' | translate }}
            </span>
            <div class="ts-input-container__data">
                <ts-simple-checkbox formControlName="multiOrder"></ts-simple-checkbox>
            </div>
        </div>

        <div class="cd-item">
            <span class="ts-input-container__header__primary__text">
                {{ 'companies.orderConfigModule.startOrder' | translate }}
            </span>
            <div class="ts-input-container__data">
                <ts-simple-checkbox formControlName="startOrder"></ts-simple-checkbox>
            </div>
        </div>

        <div class="cd-item">
            <span class="ts-input-container__header__primary__text">
                {{ 'companies.orderConfigModule.endOrder' | translate }}
            </span>
            <div class="ts-input-container__data">
                <ts-simple-checkbox formControlName="endOrder"></ts-simple-checkbox>
            </div>
        </div>

        <!-- End of Orders -->

        <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>

    </div>

    <div class="cd-row">
        <span class="ts-input-container__header__primary__text">
            {{ 'companies.deviceModule.qualityControls' | translate }}
        </span>
        <div class="ts-input-container__data">
            <ts-simple-checkbox formControlName="qualityControls"></ts-simple-checkbox>
        </div>
    </div>

    <div class="cd-row">
        <span class="ts-input-container__header__primary__text">
            {{ 'companies.deviceModule.rawMaterial' | translate }}
        </span>
        <div class="ts-input-container__data">
            <ts-simple-checkbox formControlName="rawMaterial"></ts-simple-checkbox>
        </div>
    </div>

    <div class="cd-row">
        <span class="ts-input-container__header__primary__text">
            {{ 'companies.deviceModule.parameters' | translate }}
        </span>
        <div class="ts-input-container__data">
            <ts-simple-checkbox formControlName="parameters"></ts-simple-checkbox>
        </div>
    </div>

    <div class="cd-row">
        <span class="ts-input-container__header__primary__text">
            {{ 'companies.deviceModule.operatorsLogin' | translate }}
        </span>
        <div class="ts-input-container__data">
            <ts-simple-checkbox formControlName="operatorsLogin"></ts-simple-checkbox>
        </div>
    </div>
</form>