import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../../services/auth.service';
import { UiService } from '../../../services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loading = false;

    passwordLost = false;

    loginForm = new FormGroup({
        mail: new FormControl('', [
            Validators.required
        ]),

        password: new FormControl('', [
            Validators.required
        ])
    });

    resetForm = new FormGroup({
        recover_mail: new FormControl('', [
            Validators.required
        ])
    });

    customValidationDict = {
        required: 'common.inputErrors.required',
        min: 'common.inputErrors.min'
    };

    constructor(
        private _auth: AuthService,
        private _ui: UiService,
        private _router: Router,
        private _translate: TranslateService,
        private _user: UserService
    ) { }

    ngOnInit() {
        if (this._auth.authenticated) {
            this._router.navigate(['/stops']);
        }
    }

    async loginSubmit(): Promise<any> {
        if (!this.loginForm.valid) return;

        try {
            this.loading = true;
            const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
            await this._auth.login(this.loginForm.value.mail, this.loginForm.value.password, lang);

            this._translate.get('login.success').subscribe((res) => {
                this._ui.showNotification(res);
            });
            this._router.navigate(['/stops']);
        } catch (err) {
            if (err.status === 401) {
                this._translate.get('login.wrong').subscribe((res) => {
                    this._ui.showNotification(res, 'alert');
                });
            } else if (err.message === 'no_role') {
                this._translate.get('login.wrong').subscribe((res) => {
                    this._ui.showNotification(res, 'alert');
                });
            } else {
                this._translate.get('login.unexpected').subscribe((res) => {
                    this._ui.showNotification(res, 'alert');
                });
            }
        }

        this.loading = false;
    }

    async resetSubmit(): Promise<any> {
        if (!this.resetForm.valid) return;

        try {
            this.loading = true;
            const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

            await this._user.resetPassword(this.resetForm.value.recover_mail, lang);
            this._translate.get('login.recover.success').subscribe((res) => {
                this._ui.showNotification(res);
            });

            this.passwordLost = false;

        } catch (err) {
            console.log(err);
            this._translate.get('login.recover.wrong').subscribe((res) => {
                this._ui.showNotification(res, 'alert');
            });
        }

        this.loading = false;

    }

    isPasswordLost(is: boolean) {
        this.passwordLost = is ? true : false;
    }
}
