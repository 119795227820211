<div class="control-page">
    <div class="content">
        <div class="header">
            <div class="header-row">
                <ts-button [type]="'primary small white'" icon="arrow-left"
                           (buttonClicked)="handleCancelClicked()"></ts-button>
                <span>{{'control.title' | translate}}</span>
            </div>
        </div>

        <div class="content-wrapper">
            <form [formGroup]="form" *ngIf="form" class="section">
                <div class="section">
                    <ts-text-box primaryText="{{'control.description' | translate}} *"
                                 placeholderText="{{'control.description' | translate}}"
                                 [validationDict]="customValidationDict | translateJSON | async"
                                 formControlName="description" [enabled]="!editMode" [showErrors]="true"></ts-text-box>
                </div>

                <div class="section">
                    <div class="title">{{'control.scheduling' | translate}}</div>
                    <div formArrayName="schedulings">
                        <div *ngFor="let scheduling of _schedulings.controls; let i = index" [formGroupName]="i">
                            <div class="row">
                                <ts-select [data]="selectSchedulings" [clearable]="true"
                                           placeholderText=" {{'control.time-scheduling' | translate}}" [enabled]="true"
                                           [showErrors]="true" primaryText="{{'control.time-scheduling' | translate}} *"
                                           [value]="scheduling.get('option').value.description"
                                           formControlName="option.optionId"
                                           (valueChange)="schedulingOptionChange($event, i)"
                                           [validationDict]="customValidationDict | translateJSON | async">
                                </ts-select>
                                <ts-number-box *ngIf="isQuantityRequired(scheduling.get('option').value.optionId)"
                                               [primaryText]="getQuantityLabel(scheduling.get('option').value.optionId)"
                                               [placeholderText]="getQuantityLabel(scheduling.get('option').value.optionId)"
                                               [validationDict]="customValidationDict | translateJSON | async"
                                               formControlName="quantity" [enabled]="true" [showErrors]="true">
                                </ts-number-box>
                                <ts-select *ngIf="errors && scheduling.get('option').value.optionId === 6"
                                           [data]="tmpErrors" [clearable]="true"
                                           placeholderText=" {{'control.stops' | translate}}" [enabled]="true"
                                           [showErrors]="true" primaryText="{{'control.stops' | translate}} *"
                                           [value]="displayErrorAccordingToID(scheduling.get('errorId').value)"
                                           formControlName="errorId" (valueChange)="selectStopReason($event, i)"
                                           [validationDict]="customValidationDict | translateJSON | async">
                                </ts-select>
                                <!-- Phase ID -->
                                <!-- <ts-select *ngIf="wokrProcessPhasesComputed?.length && scheduling.get('option').value.optionId === 7"
                                           [data]="wokrProcessPhasesComputed" [clearable]="true" labelKey="value"
                                           placeholderText=" {{'control.phases' | translate}}" [enabled]="true"
                                           [showErrors]="true" primaryText="{{'control.phases' | translate}} *"
                                           formControlName="phaseId"
                                           [validationDict]="customValidationDict | translateJSON | async">
                                </ts-select> -->
                                <ts-button [type]="'tertiary medium alert'" icon="trash-alt" iconSize="1x"
                                           (buttonClicked)="deleteSchedulingIfExists(i)"></ts-button>
                            </div>
                        </div>
                    </div>

                    <ts-button text="+ {{'control.add-scheduling' | translate}}" type="secondary medium blue"
                               (buttonClicked)="addEmptyScheduling()"
                               [disabled]="form.get('schedulings').length >= 3"></ts-button>
                </div>

                <!-- Associations -->
                <div class="section">
                    <div class="title">{{'control.associations' | translate}}</div>
                    <div class="row">
                        <div class="col">
                            <span class="select-label">{{'control.device' | translate}}</span>
                            <div class="ts-input-container__data">
                                <!--
                                            Vapor sets the ts-invalid class when showErrors is true regardless of whether the
                                            control is invalid and touched like other components, such as ts-autocomplete,
                                            do: the long term solution would be to fix Vapor, meanwhile we check the control
                                            ourselves
                                        -->
                                <ts-simple-select [data]="deviceOptions" [multiSelect]="true" [clearable]="true"
                                                  [showErrors]="false" formControlName="devices"
                                                  [value]="selectedDeviceOptions"
                                                  (valueChange)="onDevicesChange($event)"
                                                  placeholderText="{{'control.device' | translate}}"></ts-simple-select>
                            </div>
                        </div>

                        <div class="col">
                            <span class="select-label">{{'control.product' | translate}}</span>
                            <div class="ts-input-container__data">
                                <!--
                                            Vapor sets the ts-invalid class when showErrors is true regardless of whether the
                                            control is invalid and touched like other components, such as ts-autocomplete,
                                            do: the long term solution would be to fix Vapor, meanwhile we check the control
                                            ourselves
                                        -->
                                <ts-simple-select [data]="productOptions" [multiSelect]="true" [clearable]="true"
                                                  [showErrors]="false" formControlName="products"
                                                  [value]="selectedProductOptions"
                                                  (valueChange)="onProductsChange($event)"
                                                  placeholderText="{{'control.product' | translate}}"></ts-simple-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section">
                    <div class="title">{{'control.other-info' | translate}}</div>
                    <app-toggle [id]="'idCodeEnabled'" label="{{'control.id-code' | translate}}"
                                [value]="form.get('idCodeEnabled').value"
                                (onChange)="handleCodeEnabledChange($event)"></app-toggle>
                </div>
                <!-- Tasks Table -->
                <div class="section">
                    <div class="title">{{'control.questions' | translate}}</div>
                    <div class="column" style="width: 100%;">
                        <div class="row" style="justify-content: flex-end; margin: 10px 0;">
                            <ts-button text="+ {{'control.add-question' | translate}}" type="primary large blue"
                                       (buttonClicked)="showTaskDrawer('create')"></ts-button>
                        </div>

                        <ts-tree-list #tasksTable [data]="tasks" [columns]="taskColumns" key="id" parentKey="groupId"
                                      emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                                      [enablePaging]="false" [showSearchBar]="false" [hideActionsColumn]="true"
                                      [showRowLines]="true" [showBorders]="true" [allowRowDeleting]="false"
                                      [allowRowInserting]="false" [allowRowUpdating]="false" [wordWrapEnabled]="true">

                            <ts-tree-list-row-dragging [allowDropInsideItem]="false" [allowReordering]="true"
                                                       [showDragIcons]="true" [onReorder]="onReorder"
                                                       [onDragChange]="onDragChange"></ts-tree-list-row-dragging>
                            <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>
                            <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data>
                                <div style="display:flex; justify-content: center;" *ngIf="tplKey === 'tplEnabled'">
                                    <ts-simple-checkbox [value]="data.ControlsTasks[0].enabled"
                                                        (valueChange)="enablingTask(data.id, $event)"></ts-simple-checkbox>
                                </div>

                                <span *ngIf="tplKey === 'tplActions'">

                                    <ts-tree-list-button icon="pencil" title="Edit"
                                                         (click)="showTaskDrawer('edit',data)"></ts-tree-list-button>
                                    <ts-tree-list-button icon="copy" title="Copy"
                                                         (click)="showTaskDrawer('duplicate',data)"></ts-tree-list-button>
                                    <ts-tree-list-button icon="trash-alt" title="Delete" (click)="deleteTask(data.id)"
                                                         [isDanger]="true"></ts-tree-list-button>
                                </span>
                            </ng-template>
                        </ts-tree-list>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="control-footer">
    <div>
        <ts-button [type]="'secondary medium blue'" (buttonClicked)="handleCancelClicked()"
                   text="{{'orders.createEdit.cancelButton' | translate}}">
        </ts-button>
        <ts-button [type]="'primary medium blue'" (buttonClicked)="onFormSubmit()" [disabled]="!formValid"
                   text="{{'orders.createEdit.saveButton' | translate}}">
        </ts-button>
    </div>
</div>

<ng-template #newTaskTemplate>
    <app-control-task-drawer #taskDrawer [companyID]="selectedCompanyId" [plantID]="selectedPlantId"
                             (selectedTaskEmit)="onSelectedTaskEmit($event)" [controlID]="control.id"
                             [currentTasks]="tasks"></app-control-task-drawer>
</ng-template>

<ng-template #taskEditTemplate>
    <app-control-task-drawer #taskDrawer [controlTask]="selectedTask" [companyID]="selectedCompanyId"
                             [plantID]="selectedPlantId" [currentTasks]="tasks" [controlID]="control.id"
                             (selectedTaskEmit)="onSelectedTaskEmit($event)"></app-control-task-drawer>
</ng-template>
<ng-template #taskDuplicateTemplate>
    <app-control-task-drawer #taskDrawer [isCloning]="true" [controlTask]="selectedTask" [companyID]="selectedCompanyId"
                             [plantID]="selectedPlantId" [currentTasks]="tasks" [controlID]="control.id"
                             (selectedTaskEmit)="onSelectedTaskEmit($event)"></app-control-task-drawer>
</ng-template>


<ng-template #taskDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="onTaskFormSubmit()"
                   [disableExit]="!taskDrawer?.formValid">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>