<div class="navbar-login" *ngIf="isLoginPage()">
  <div class="side left"></div>
  <div class="side right">
    <i *ngIf="!isLoginPage()" class="icon icon-logout" (click)="logout()"></i>
    <img class="lang-icon" src="./assets/images/lang/{{currentLang}}.svg" (click)="openMenu = !openMenu">
    <div class="lang-dropdown" [ngClass]="{ 'show': openMenu }">
      <ng-template ngFor let-lang [ngForOf]="languages">
        <div class="lang-button" (click)="setLanguage(lang.code)">
          <img class="lang-icon" src="./assets/images/lang/{{lang.code}}.svg">
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ts-ui-shell #uiShell *ngIf="!isLoginPage()">
  <ts-ui-shell-header>
    <ts-ui-shell-header-left-column>
      <div class="ts-custom-header-left">
        <img src="../../../../assets/images/team_system//site_logo.png" width="220" />
      </div>
    </ts-ui-shell-header-left-column>
    <ts-ui-shell-header-right-column>
      <!-- Header Right side -->
      <div class="ts-custom-header-right right-nav">
        <app-company-switcher *ngIf="isAdmin && companies && selectedCompany" [companies]="companies"
                              [selectedCompany]="selectedCompany"
                              (onCompanyChange)="updateSelectedCompany($event)"></app-company-switcher>

        <div #profileElement (click)="showUserProfilePopOver()" style="cursor: pointer;">
          <ts-avatar [text]="displayAvatarCharacters()" little="true" rounded="true"></ts-avatar>
        </div>

        <ts-simple-popover [(visible)]="userPopoverVisible" [target]="profileElement" position="bottomRight"
                           (close)="userPopoverVisible = false" [showArrow]="false">

          <div class="custom-popover-wrapper">
            <!-- Header -->
            <div class="custom-popover-header">
              <p *ngIf="_auth.user && _auth.user.lastName">{{_auth.user.lastName + ' ' + _auth.user.firstName}}</p>
              <p *ngIf="_auth.user && _auth.user.email && !_auth.user.lastName">{{_auth.user.email}}</p>
            </div>

            <!-- Body -->
            <div>
              <ts-select primaryText="{{'common.language' | translate}}" [data]="languages" [value]="selectedLanguage"
                         [clearable]="false" [enableSearch]="false" (valueChange)="onLanguageChange($event)"
                         labelKey="label"></ts-select>
            </div>
            <div>
              <ts-button text="Logout" type="primary large blue" (buttonClicked)="logout()"></ts-button>
            </div>

          </div>

        </ts-simple-popover>

      </div>
    </ts-ui-shell-header-right-column>
  </ts-ui-shell-header>
  <ts-ui-shell-sidebar appClickOutside (clickOutside)="closeSidebarSecondaryPanel()">
    <ng-container *ngFor="let route of routes">
      <ts-ui-shell-sidebar-item *ngIf="shouldDisplayRoute(route)" [to]="route.to" [label]="route.label"
                                [tooltipLabel]="route.tooltipLabel" [disabled]="route.disabled"
                                [externalUrl]="route.externalUrl">

        <ts-icon [name]="route.icon"></ts-icon>
        <ng-container *ngFor="let secondaryItem of route?.secondaryItems">
          <ts-ui-shell-secondary-item *ngIf="shouldDisplayRoute(secondaryItem)" [to]="secondaryItem?.to"
                                      [label]="secondaryItem?.label">
          </ts-ui-shell-secondary-item>
        </ng-container>

      </ts-ui-shell-sidebar-item>
    </ng-container>

  </ts-ui-shell-sidebar>

  <ts-ui-shell-main padding="false">
    <router-outlet></router-outlet>
  </ts-ui-shell-main>
</ts-ui-shell>

<router-outlet *ngIf="isLoginPage()"></router-outlet>